.mod-recipe {
  background-color: $white;
  .middle-line {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 1px;
    border-right: 1px solid #DCD5CE;
    &.right {
      right: 0;
      left: auto;
      top: 20px;
      bottom: 20px;
    }
  }
  .information {
    text-align: center;
    padding-bottom: 70px;
    @media ($smartphone) {
      padding-bottom: 20px;
      .ws-3 {
        display: none;
      }
      .ws-6 {
        width: 100%;
      }
    }
    .mini-headline {
      font-size: 18px;
      line-height: 24px;
      color: #5F5F5F;
      text-align: center;
      padding-bottom: 15px;
    }
    .person-card {
      border-radius: 5px;
      overflow: hidden;
      width: 300px;
      background-color: #F6F6F6;
      @include clearfix();
      @include inline();
    }
    .image {
      float: left;
    }
    .text {
      overflow: hidden;
      color: #424242;
      padding: 15px 20px 0 20px;
      text-align: left;
      .tag {
        font-size: 12px;
        line-height: 14px;
        padding-bottom: 3px;
      }
      .headline {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
  .facts {
    background-color: #F8F6F4;
    color: #4A4A4A;
    display: flex;
    height: 300px;
    @media ($smartphone) {
      .inner {
        padding-top: 20px;
        .w-34 {
          width: 100%;
          padding-top: 10px;
          padding-bottom: 20px;
        }
        .w-33 {
          width: 50%;
        }
      }
      @include uncrow();
      // > .crow {
      //   @include uncrow();
      // }
    }
    .facts-content  {
      max-width: 864px;
      width: 100%;
      margin: 0 auto;
    }
    > .ws-6 {
      height: 100%;
    }
    .image {
      max-width: 130px;
      @media ($tablet) {
        max-width: 100px;
      }
      @media ($smartphone) {
        max-width: 80px;
      }
    }
    .inner {
      //margin-top: 35px;
      width: 100%;
    }
    .circle {
      @include inline();
    }
    .label {
      font-size: 12px;
      line-height: 16px;
      padding: 0 15px;
      @include inline();
    }
    .icon {
      &.icon-pot-filled,
      &.icon-pot-empty {
        color: $blue;
        font-size: 28px;
        line-height: 28px;

        // @at-root {
        //   .site-jarlsberg & {
        //     color: $jarlsberg-blue;
        //   }
        // }
      }
    }
    .product {
      font-size: 18px;
      line-height: 24px;
      color: #4A4A4A;
      @media ($smartphone) {
        padding-top: 20px;
      }
      .image {
        float: left;
        @media ($smartphone) {
          margin-left: 15px;
        }
      }
      .text {
        overflow: hidden;
        font-weight: 300;
        padding: 20px;
        display: inline-block;
        @media ($smartphone) {
          font-size: 16px;
          line-height: 20px;
          padding: 0 20px 20px 20px;
        }
        span {
          font-weight: 400;
        }
      }
      .read-more {
        margin-top: 10px;
        display: block;
        font-size: 14px;
        // @media ($smartphone) {
        //   font-size: 12px;
        //   line-height: 14px;
        // }
      }
    }
    .nutrition {
      font-size: 18px;
      line-height: 28px;
      font-weight: 400;
      padding-top: 20px;
      padding-left: 50px;
      @media ($tablet) {
        padding-left: 25px;
      }
      @media ($smartphone) {
        padding-left: 20px;
        padding-top: 40px;
        padding-bottom: 25px;
        border-bottom: 1px solid #DCD5CE;
      }
      .headline {
        text-transform: uppercase;
      }
      ul {
        li {
          padding: 10px 30px 0 0;
          @media ($smartphone) {
            padding: 10px 15px 0 0;
          }
        }
      }
    }
  }

  .ingredients {
    @media ($smartphone) {
      @include uncrow();
    }
    .amount-adjustor {
      background-color: #F6F6F6;
      padding: 7px 5px 0 5px;
      margin-bottom: 30px;
      @include inline();
      @media ($tablet) {
        padding: 17px 5px 10px 5px;
      }
      &--hide { display: none; }
      ul {
        text-align: left;
      }
      .image {
        @media ($tablet) {
          max-width: 25px;
        }
      }
      .label {
        font-size: 18px;
        line-height: 18px;
        color: #4A4A4A;
        text-transform: uppercase;
        margin: 0 20px 0 10px;
        @media ($tablet) {
          font-size: 14px;
          line-height: 14px;
          margin: 0 10px 0 10px;
        }
        @media ($smartphone) {
          margin: 0 10px 0 5px;
        }
      }
      .minus,
      .plus {
        display: block;
        width: 40px;
        height: 30px;
        margin-top: -5px;
      }
      .minus {
        border-right: 1px solid #E0E0E0;
      }
      .icon {
        font-size: 16px;
        line-height: 16px;
        @media ($tablet) {
          font-size: 13px;
          line-height: 13px;
        }
        &.icon-minus {
          font-size: 4px;
          line-height: 4px;
          @media ($tablet) {
            font-size: 3px;
            line-height: 3px;
          }
        }
      }
    }
    .headline {
      color: #4A4A4A;
      font-size: 18px;
      line-height: 24px;
      padding: 20px 0 25px 0;
      text-transform: uppercase;
      font-weight: 400;
    }
    .inner {
      padding-left: 20px;
      padding-bottom: 20px;
      padding-right: 50px;
      @media ($tablet) {
        padding-right: 20px;
      }
    }
    ol,
    p {
      margin: 0 0 20px 0;
      padding: 0 0 0 20px;
      color: #5F5F5F;
      font-size: 18px;
      line-height: 24px;
      font-weight: 300;
      li {
        margin-bottom: 20px;
      }
    }
    p {
      padding-left: 0;
    }
    .ingredient-list {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 20px;
      color: #5F5F5F;
      li {
        margin: 5px 0;
        span {
          span {
            color: $blue;

            @at-root {
              .site-jarlsberg & {
                color: $jarlsberg-blue;
              }
            }
          }
        }
      }
    }
  }
  .splash {
    position: absolute;
    top: 0;
    &.video {
      left: 50%;
      margin-left: -36px;
      margin-top: -36px;
      @media ($smartphone) {
        left: 20px;
        width: 56px;
        height: 56px;
        margin-top: -28px;
        margin-left: 0;
      }
    }
    &.facebook {
      right: 90px;
      margin-top: -28px;
      @media ($tablet) {
        right: 90px;
        margin-top: -20px;
      }
    }
    &.pinterest {
      right: 20px;
      margin-top: -28px;
      margin-right: 0;
      @media ($tablet) {
        margin-top: -20px;
      }
    }
  }
  .quote {
    margin-top: 60px;
    margin-bottom: 30px;
    text-align: center;
    @media ($smartphone) {
      padding-left: 15px;
      padding-right: 15px;
      q {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
  q {
    font-size: 24px;
    line-height: 28px;
    font-style: italic;
    color: #5F5F5F;
  }

  .circle-loader {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-image: url("../images/full-circle.png");
    background-size: cover;
    margin-bottom: 10px;
    position: relative;
    @include inline();
    .amount {
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 9px;
      span {
        padding-top: 2px;
        font-size: 18px;
        line-height: 18px;
        font-weight: 400;
        color: $blue;

        @at-root {
          .site-jarlsberg & {
            color: $jarlsberg-blue;
          }
        }
      }
    }
    .side {
      width: 50%;
      height: 100%;
      overflow: hidden;
    }
    .spinner {
      width: 200%;
      height: 100%;
      background-size: cover;
      background-position: center center;
      transform: translateZ(0);
      position: relative;
    }
    .left {
      .spinner {
        margin-left: 0;
        transform: rotate(0deg);
        left: 1px;
      }
    }
    .right {
      .spinner {
        margin-left: -100%;
        transform: rotate(0deg);
        left: -1px;
      }
    }
    .spin {
      animation-name: spin;
      animation-duration: 4s;
      animation-timing-function: linear;
      background-size: cover;
    }
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(180deg);
    }
  }
}

.site-jarlsberg {
  .mod-recipe {
    .facts {
      .label {
        font-size: 18px;
      }
    }

    .ingredients .ingredient-list {
      font-size: 20px;
    }
  }
}

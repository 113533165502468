.mod-image-text {
  margin-bottom: 0;
  padding: 40px 0;
  &.dark {
    background-color: #262626;
    .text {
      color: $white;
    }
  }
  &.blue {
    background-color: #002B45;
    .text {
      color: $white;
    }
  }
  &.bright {
    background-color: $white;
    padding: 20px 0;
    .nest {
      padding: 0;
      > div {
        padding: 0;
      }
      @media ($tablet) {
        width: auto;
      }
    }
    hr {
      border-color: #424242;
    }
    .text {
      color: #424242;
      padding-left: 10px;
      padding-right: 30px;
      @media ($smartphone) {
        padding: 0 20px;
      }
    }
  }
  .image {
    padding: 0 40px 0 0;
    @media ($smartphone) {
      padding: 0 0 35px 0;
      margin: 0 -20px;
    }
  }
  .text {
    color: $white;
    font-family: 'Open Sans', Arial;
    .tag {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 10px;
      text-transform: uppercase;
      @media ($smartphone) {
        font-size: 14px;
        line-height: 18px;
      }
    }
    .headline,
    h1, h2, h3, h4, h5, h6 {
      font-size: 30px;
      line-height: 36px;
      font-weight: 300;
      margin: 0;
      @media ($smartphone) {
        font-size: 24px;
        line-height: 28px;
      }
    }
    p {
      font-size: 18px;
      line-height: 24px;
      @media ($smartphone) {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
  @media ($smartphone) {
    padding: 0 0 35px 0;
    .crow {
      @include uncrow();
    }
  }
}
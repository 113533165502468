.hidden {
  display: none;
}
.recipe-box {
  background-color: #F6F6F6;
  border-radius: 5px;
  display: block;
  transition: opacity .15s ease;
  overflow: hidden;
  margin-bottom: 20px;
  transform: translateZ(0);
  .image {
    width: 100%;
    padding: 0;
    float: none;
  }
  .text {
    min-height: 105px;
    padding: 20px;
    font-size: 24px;
    line-height: 30px;
    color: #4A4A4A;
    @media ($smartphone) {
      font-size: 14px;
      line-height: 18px;
      padding: 10px;
      min-height: 60px;
    }
  }
  &:hover {
    opacity: .8;
  }
}
.load-more {
  margin-top: 15px;
  text-align: center;
}
body {
  &.noscroll {
    overflow: hidden;
    position: fixed;
  }
}
.icon {
  @include inline();
  &.icon-logo-snofrisk {
    color: $white;
    font-size: 40px;
    line-height: 40px;
    margin-left: 10px;
  }
  &.icon-logo-brunost {
    color: $white;
    font-size: 56px;
    line-height: 56px;
    @media ($smartphone) {
      font-size: 36px;
      line-height: 36px;
    }
  }
	&.fas.fa-bars {
		color: $white;
		font-size: 30px;
		line-height: 30px;
	}
  &.icon-small-arrow-down {
    color: $white;
    font-size: 4px;
    line-height: 4px;
    vertical-align: middle;
    margin-top: -2px;
  }
  &.icon-big-arrow {
    color: $white;
    font-size: 50px;
    line-height: 50px;
    //margin-bottom: -100px;
  }
  &.icon-cross {
    color: $white;
    font-size: 40px;
    line-height: 40px;
  }
  &.icon-burger {
    color: $white;
    font-size: 30px;
    line-height: 30px;
  }
  &.icon-facebook,
  &.icon-instagram,
  &.icon-pinterest,
  &.icon-video {
    color: $white;
  }
  &.icon-video {
    font-size: 30px;
    line-height: 30px;
  }
  &.icon-facebook {
    font-size: 26px;
    line-height: 26px;
  }
  &.icon-instagram {
    font-size: 26px;
    line-height: 26px;
    @media ($tablet) {
      font-size: 18px;
      line-height: 18px;
    }
  }
  &.icon-pinterest {
    font-size: 38px;
    line-height: 38px;
    vertical-align: bottom;
    @media ($tablet) {
      font-size: 35px;
      line-height: 35px;
    }
  }
}

.mod-row {
  @media ($smartphone) {
    .crow {
      @include uncrow();
    }
  }
}
.mod {
  //margin-bottom: 20px;
  // @media ($smartphone) {
  //   margin: 0 -30px;
  // }
}

$circle-size: 46px;
$circle-size-mobile: 35px;
$container-padding: 10px;

.slider-arrow {
  &.left,
  &.right {
    position: absolute;
    top: 100px;

    @media ($smartphone) {
      top: calc(50% - #{$circle-size});
    }

    span {
      font-size: 26px;
      line-height: 26px;

      @media ($smartphone) {
        font-size: 1.75rem;
        line-height: 1.75rem;
      }
    }
  }
  &.left {
    left: calc((-#{$circle-size} / 2) + (#{$container-padding} * 1.5));

    @media ($desktop) {
      left: calc((-#{$circle-size} / 2) + #{$container-padding});
    }

    span {
      margin-right: 4px;
      margin-top: 1px;

      @media ($smartphone) {
        margin-right: 2px;
      }
    }
  }
  &.right {
    right: calc((-#{$circle-size} / 2) + (#{$container-padding} * 1.5));

    @media ($desktop) {
      right: calc((-#{$circle-size} / 2) + #{$container-padding});
    }

    span {
      margin-left: 4px;
      margin-top: 1px;

      @media ($smartphone) {
        margin-left: 2px;
      }
    }
  }
  &.inactive {
    visibility: hidden;
  }
}

hr {
  width: 50px;
  border: 0;
  margin: 20px 0 5px 0;
  border-bottom: 3px solid $white;
  @include inline();
  &.full {
    width: 100%;
    border-color: #EEEEEE;
    border-width: 1px;
  }

  &.dark {
    border-color: $gray;
  }
}

.circle {
  border-radius: 50%;
  width: $circle-size;
  height: $circle-size;
  background-color: lightgray;
  display: block;
  transition: background-color .15s ease;

  @media ($smartphone) {
    width: $circle-size-mobile;
    height: $circle-size-mobile;
  }

  span {
    transition: color .15s ease;
  }
  &.blue {
    background-color: #29B6F6;
    span {
      color: $white;
    }
  }
  &:hover {
    //box-shadow: 0px 2px 4px rgba(0,0,0,.5);
    background-color: #82C9E6;
    border-color: #82C9E6;
  }
  &:active {
    // box-shadow: none;
    // opacity: .8;
    background-color: $white;
    span {
      color: $blue;
    }
  }
  &.video {
    width: 74px;
    height: 74px;
    background-color: #82C9E6;
    span {
      margin-left: 5px;
    }
  }
  &.facebook {
    background-color: #4A90E2;
  }
  &.pinterest {
    background-color: #DA0023;
  }
  &.instagram {
    background-image: url(../images/pinterest-circle.png);
    background-size: cover;
  }
  &.youtube {
    border-radius: 0%;
    background-image: url(../images/youtube-logo-46.png);

    width: 66px;
    height: 46px;
    background-color: transparent;
    margin-left: 6px;
  }

  @media ($tablet) {
    width: 40px;
    height: 40px;

    &.youtube{
      background-image: url(../images/youtube-logo-40.png);
      height: 40px;
      width: 57px;
    }
  }
  @media ($smartphone) {
    &.youtube{
      background-image: url(../images/youtube-logo-35.png);
      height: 35px;
      width: 50px;
    }
  }

}

.btn {
  border: 1px solid transparent;
  text-transform: uppercase;
  border-radius: 5px;
  padding: 10px 25px;
  font-size: 18px;
  line-height: 18px;
  @include inline();
  transition: background-color .15s ease;
  font-weight: 400;
  &:active {
    span {
      transform: translateY(-2px);
    }
  }
  span {
    display: block;
    transition: transform .15s ease,
                color .15s ease;
  }
  @media ($smartphone) {
    font-size: 14px;
    line-height: 14px;
    padding: 8px 15px;
  }
  &.small {
    padding: 8px 15px;
  }
  &.white {
    border-color: $white;
    background-color: rgba(255, 255, 255, .1);
    margin-bottom: 30px;
    span {
      color: $white;
    }
    @media ($desktop) {
      &:hover {
        background-color: $white;
        span {
          color: #17B5F9;

          @at-root {
            .site-jarlsberg & {
              color: $jarlsberg-blue;
            }
          }
        }
      }
    }
  }
  &.blue {
    border-color: $blue;
    @at-root {
      .site-jarlsberg & {
        border-color: $jarlsberg-blue;
      }
    }

    span {
      color: $blue;

      @at-root {
        .site-jarlsberg & {
          color: $jarlsberg-blue;
        }
      }
    }
    &.filled {
      background-color: $blue;

      @at-root {
        .site-jarlsberg & {
          background-color: $jarlsberg-blue;
        }
      }
      span {
        color: $white;
      }
    }
    @media ($desktop) {
      &:hover {
        background-color: #82C9E6;
        border-color: #82C9E6;

        @at-root {
          .site-jarlsberg & {
            background-color: darken($jarlsberg-blue, 10);
            border-color:darken($jarlsberg-blue, 10);
          }
        }
      }
    }
    &:active {
      background-color: $white;
      span {
        color: $blue;

        @at-root {
          .site-jarlsberg & {
            color: $jarlsberg-blue;
          }
        }
      }
    }
  }
}

.site-jarlsberg {
  .circle {
    &.blue {
      background-color: $jarlsberg-blue;
    }

    &:hover {
      background-color: lighten($jarlsberg-blue, 10);
      border-color: lighten($jarlsberg-blue, 10);
    }

    &:active {
      span {
        color: $jarlsberg-blue;
      }
    }

    &.video {
      background-color: lighten($jarlsberg-blue, 10);
    }
  }

  .btn {
    &.blue {
      border-color: $jarlsberg-blue;

      &.filled {
        background-color: $jarlsberg-blue;
        color: $white;
      }

      @media ($desktop) {
        &:hover {
          background-color: lighten($jarlsberg-blue, 10);
          border-color: lighten($jarlsberg-blue, 10);
        }
      }

      &:active {
        span {
          color: $jarlsberg-blue;
        }
      }
    }
  }
}

$white            : #fff;
$gray             : #666666;
$gray-light       : #bbbbbb;
$black            : #000;

$light-blue       : #82C9E6;
$blue             : #29B6F6;
$text-blue        : #4d8daf;

$main-color       : $blue;

$link-color       : #00ABFF;
$link-hover-color : darken(#00ABFF, 5%);
$text-color       : $black;

$bg-color         : #fafafa;

$font-family      : 'Open Sans', Arial;
$font-size        : 16px;
$line-height      : 24px;

$desktop:                "min-width: 1200px";
$tablet:                 "min-width: 768px) and (max-width: 1199px";
$tablet-landscape:       "orientation: landscape) and (min-width: 768px) and (max-width: 1199px";
$tablet-portrait:        "orientation: portrait) and (min-width: 768px) and (max-width: 1199px";
$smartphone:             "max-width: 767px";
$smartphone-landscape:   "orientation: landscape) and (max-width: 767px";
$smartphone-portrait:    "orientation: portrait) and (max-width: 767px";
$landscape:              "orientation: landscape";
$portrait:               "orientation: portrait";

$jarlsberg-bright: #f2ede6;
$jarlsberg-dark: #060303;
$jarlsberg-header-font: 'gibson-semibold', Arial;
$jarlsberg-headline-font: 'gibson-regular', Arial;
$jarlsberg-text-font: 'eideticmodern_regular', Arial;
// from Jarlsberg design manual
$jarlsberg-yellow: #f1c65c;
$jarlsberg-blue: #01426A;
$jarlsberg-red: #c7102d;

$tineasia-blue: #002B45;
$tineasia-blue-light: #F5F5F5;
$tineasia-orange: #FCC270;
$tineasia-gray: #D9D9D9;

$tineasia-font: "Telefon";
$tineasia-font-size: 16px;


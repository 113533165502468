.fullwidth-card {
  > .crow {
    margin: 0;
    padding: 0;
  }
  .mod-card {
    margin: 0;
    padding: 0;
  }
}
.mod-card {
  margin: 20px 0;
  background-size: cover;
  background-position: center center;
  //height: 380px;
  @media ($smartphone) {
    width: 100% !important;
    height: auto;
    margin: 10px 0;
  }
  @media ($tablet) {
    background-image: none;
  }
  @media ($desktop) {
    background-image: none;
  }
  &.no-spacing {
    margin: 0;
  }
  .tone {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,.15);
  }
  .image {
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center center;
    height: 300px;
    // figure  {
    //   background-size: cover;
    //   background-position: center center;
    //   transition: transform .5s ease;
    //   height: 100%;
    //   width: 100%;
    // }
    img {
      opacity: 0;
      @media ($smartphone) {
        display: none;
      }
    }
    @media ($smartphone) {
      height: auto;
    }
  }
  .holder {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    transition: top .5s ease,
                bottom .5s ease;
    top: 0;
    @media ($smartphone) {
      position: static;
      top: 0 !important;
    }
    @media ($tablet) {
      top: 0 !important;
    }
  }
  .text {
    position: absolute;
    left: 0;
    width: 100%;
    color: $white;
    padding: 30px;
    bottom: 0;
    font-weight: 400;
    @media ($desktop) {
      min-height: 284px;
    }
    @media ($tablet) {
      bottom: 0;
      p {
        max-width: 230px;
      }
    }
    @media ($smartphone) {
      bottom: 0;
      padding: 35px 25px 20px 25px;
      position: static;
    }
    .tag {
      font-size: 18px;
      line-height: 24px;
      @media ($tablet) {
        font-size: 14px;
        line-height: 18px;
      }
      @media ($smartphone) {
        font-size: 14px;
        line-height: 18px;
      }
    }
    .headline {
      font-size: 36px;
      line-height: 42px;
      font-weight: 600;
      font-family: 'DIN Next', Arial;
      @media ($tablet) {
        font-size: 24px;
        line-height: 30px;
      }
      @media ($smartphone) {
        font-size: 24px;
        line-height: 30px;
      }
    }
    hr {
      @media ($desktop) {
        margin-top: 30px;
      }
    }
    p {
      font-size: 18px;
      line-height: 24px;
      @media ($tablet) {
        font-size: 14px;
        line-height: 18px;
      }
      @media ($smartphone) {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
  &:hover {
    @media ($desktop) {
      .holder {
        top: 0 !important;
      }
      .image {
        figure {
          transform: scale(1.1, 1.1);
        }
      }
    }
  }
}
.site-brunost {
  .mod-card {
    .text {
      .headline {
        font-family: 'Sanchez', Arial;
      }
    }
  }
}

.site-jarlsberg {
	.mod-card .text {
	  .headline {
		  font-family: $jarlsberg-header-font;
	  }
  }
}

.mod-recipeslider {
  margin-bottom: 0;
  padding: 55px 0;
  background-color: $white;
  .header {
    padding: 0 0 20px 10px;
    @media ($smartphone) {
      padding-bottom: 30px;
    }
  }
  .head-tag {
    font-size: 24px;
    line-height: 32px;
    text-transform: uppercase;
    color: #4A4A4A;
    @media ($smartphone) {
      font-size: 16px;
      line-height: 22px;
    }
  }
  .headline {
    font-size: 48px;
    line-height: 54px;
    @media ($smartphone) {
      font-size: 24px;
      line-height: 30px;
    }
  }

  .slider {
    position: relative;
    opacity: 0;
    max-width: 800px;

    .slider-viewport {
      overflow: hidden;
      .stripe {
        width: 300%; // THIS SHOULD BE SET WITH JS IN THE FRONTEND
        @media ($smartphone) {
          width: 600%;
        }
        .slide {
          padding: 0;
          max-width: 300px;
          &.last-slide {
            height: 352px;
            @media ($tablet) {
              height: 281px;
            }
            @media ($smartphone) {
              height: auto;
            }
            .w-80 {
              @media ($smartphone) {
                width: 100%;
              }
            }
            .text {
              text-align: center;
            }
            .btn {
              margin-top: 20px;
            }
            .small {
              font-size: 18px;
              line-height: 24px;
            }
            .ws-12 {
              height: 100%;
              .recipe-box {
                height: 100%;
              }
            }
          }
          .text {
            padding: 15px;
            .tag {
              font-size: 14px;
              line-height: 14px;
            }
            p {
              font-size: 18px;
              line-height: 24px;
              margin: 0;
            }
          }
          .recipe-box{
            max-width: 300px;
          }
        }
      }
    }
  }
}


.site-jarlsberg {
  .mod-recipeslider {
    .headline {
      font-family: $jarlsberg-header-font;
    }
  }
}

.site-brunost {
  .mod-recipeslider {
    .headline {
      font-family: 'Sanchez', Arial;
    }
  }
}

.site-tineasia {
  .slider-container {
    display: flex;
    justify-content: center;
  }
}

@font-face {
  font-family: 'icomoon';
  src:  url('../icomoon/fonts/icomoon.eot?h96rja');
  src:  url('../icomoon/fonts/icomoon.eot?h96rja#iefix') format('embedded-opentype'),
    url('../icomoon/fonts/icomoon.ttf?h96rja') format('truetype'),
    url('../icomoon/fonts/icomoon.woff?h96rja') format('woff'),
    url('../icomoon/fonts/icomoon.svg?h96rja#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-pin-heart .path1:before {
  content: "\e95e";
  color: rgb(223, 29, 46);
}
.icon-pin-heart .path2:before {
  content: "\e95f";
  margin-left: -1.181640625em;
  color: rgb(255, 85, 39);
}
.icon-pin-heart .path3:before {
  content: "\e960";
  margin-left: -1.181640625em;
  color: rgb(255, 85, 39);
}
.icon-big-arrow:before {
  content: "\e95d";
}
.icon-tine-logo .path1:before {
  content: "\e951";
  color: rgb(255, 255, 255);
}
.icon-tine-logo .path2:before {
  content: "\e952";
  margin-left: -1.5791015625em;
  color: rgb(255, 255, 255);
}
.icon-tine-logo .path3:before {
  content: "\e953";
  margin-left: -1.5791015625em;
  color: rgb(216, 35, 50);
}
.icon-tine-logo .path4:before {
  content: "\e954";
  margin-left: -1.5791015625em;
  color: rgb(7, 48, 93);
}
.icon-tine-logo .path5:before {
  content: "\e955";
  margin-left: -1.5791015625em;
  color: rgb(7, 48, 93);
}
.icon-tine-logo .path6:before {
  content: "\e956";
  margin-left: -1.5791015625em;
  color: rgb(7, 48, 93);
}
.icon-tine-logo .path7:before {
  content: "\e957";
  margin-left: -1.5791015625em;
  color: rgb(7, 48, 93);
}
.icon-tine-logo .path8:before {
  content: "\e958";
  margin-left: -1.5791015625em;
  color: rgb(7, 48, 93);
}
.icon-tine-logo .path9:before {
  content: "\e959";
  margin-left: -1.5791015625em;
  color: rgb(255, 255, 255);
}
.icon-tine-logo .path10:before {
  content: "\e95a";
  margin-left: -1.5791015625em;
  color: rgb(255, 255, 255);
}
.icon-tine-logo .path11:before {
  content: "\e95b";
  margin-left: -1.5791015625em;
  color: rgb(255, 255, 255);
}
.icon-tine-logo .path12:before {
  content: "\e95c";
  margin-left: -1.5791015625em;
  color: rgb(255, 255, 255);
}
.icon-logo-brunost .path1:before {
  content: "\e901";
  color: rgb(216, 35, 49);
}
.icon-logo-brunost .path2:before {
  content: "\e902";
  margin-left: -2.9658203125em;
  color: rgb(170, 205, 216);
}
.icon-logo-brunost .path3:before {
  content: "\e903";
  margin-left: -2.9658203125em;
  color: rgb(170, 205, 216);
}
.icon-logo-brunost .path4:before {
  content: "\e904";
  margin-left: -2.9658203125em;
  color: rgb(216, 35, 49);
}
.icon-logo-brunost .path5:before {
  content: "\e905";
  margin-left: -2.9658203125em;
  color: rgb(170, 205, 216);
}
.icon-logo-brunost .path6:before {
  content: "\e906";
  margin-left: -2.9658203125em;
  color: rgb(170, 205, 216);
}
.icon-logo-brunost .path7:before {
  content: "\e907";
  margin-left: -2.9658203125em;
  color: rgb(255, 255, 255);
}
.icon-logo-brunost .path8:before {
  content: "\e908";
  margin-left: -2.9658203125em;
  color: rgb(36, 57, 83);
}
.icon-logo-brunost .path9:before {
  content: "\e909";
  margin-left: -2.9658203125em;
  color: rgb(36, 57, 83);
}
.icon-logo-brunost .path10:before {
  content: "\e90a";
  margin-left: -2.9658203125em;
  color: rgb(36, 57, 83);
}
.icon-logo-brunost .path11:before {
  content: "\e90b";
  margin-left: -2.9658203125em;
  color: rgb(36, 57, 83);
}
.icon-logo-brunost .path12:before {
  content: "\e90c";
  margin-left: -2.9658203125em;
  color: rgb(36, 57, 83);
}
.icon-logo-brunost .path13:before {
  content: "\e93a";
  margin-left: -2.9658203125em;
  color: rgb(36, 57, 83);
}
.icon-logo-brunost .path14:before {
  content: "\e93b";
  margin-left: -2.9658203125em;
  color: rgb(216, 35, 49);
}
.icon-logo-brunost .path15:before {
  content: "\e93c";
  margin-left: -2.9658203125em;
  color: rgb(255, 255, 255);
}
.icon-logo-brunost .path16:before {
  content: "\e93d";
  margin-left: -2.9658203125em;
  color: rgb(255, 255, 255);
}
.icon-logo-brunost .path17:before {
  content: "\e93e";
  margin-left: -2.9658203125em;
  color: rgb(255, 255, 255);
}
.icon-logo-brunost .path18:before {
  content: "\e93f";
  margin-left: -2.9658203125em;
  color: rgb(255, 255, 255);
}
.icon-logo-brunost .path19:before {
  content: "\e940";
  margin-left: -2.9658203125em;
  color: rgb(36, 57, 83);
}
.icon-logo-brunost .path20:before {
  content: "\e941";
  margin-left: -2.9658203125em;
  color: rgb(36, 57, 83);
}
.icon-logo-brunost .path21:before {
  content: "\e942";
  margin-left: -2.9658203125em;
  color: rgb(36, 57, 83);
}
.icon-logo-brunost .path22:before {
  content: "\e943";
  margin-left: -2.9658203125em;
  color: rgb(36, 57, 83);
}
.icon-logo-brunost .path23:before {
  content: "\e944";
  margin-left: -2.9658203125em;
  color: rgb(36, 57, 83);
}
.icon-logo-brunost .path24:before {
  content: "\e945";
  margin-left: -2.9658203125em;
  color: rgb(36, 57, 83);
}
.icon-logo-brunost .path25:before {
  content: "\e946";
  margin-left: -2.9658203125em;
  color: rgb(36, 57, 83);
}
.icon-logo-brunost .path26:before {
  content: "\e947";
  margin-left: -2.9658203125em;
  color: rgb(36, 57, 83);
}
.icon-logo-brunost .path27:before {
  content: "\e948";
  margin-left: -2.9658203125em;
  color: rgb(36, 57, 83);
}
.icon-logo-brunost .path28:before {
  content: "\e949";
  margin-left: -2.9658203125em;
  color: rgb(36, 57, 83);
}
.icon-logo-brunost .path29:before {
  content: "\e94a";
  margin-left: -2.9658203125em;
  color: rgb(36, 57, 83);
}
.icon-logo-brunost .path30:before {
  content: "\e94b";
  margin-left: -2.9658203125em;
  color: rgb(36, 57, 83);
}
.icon-logo-brunost .path31:before {
  content: "\e94c";
  margin-left: -2.9658203125em;
  color: rgb(36, 57, 83);
}
.icon-logo-brunost .path32:before {
  content: "\e94d";
  margin-left: -2.9658203125em;
  color: rgb(36, 57, 83);
}
.icon-logo-brunost .path33:before {
  content: "\e94e";
  margin-left: -2.9658203125em;
  color: rgb(36, 57, 83);
}
.icon-logo-brunost .path34:before {
  content: "\e94f";
  margin-left: -2.9658203125em;
  color: rgb(36, 57, 83);
}
.icon-logo-brunost .path35:before {
  content: "\e950";
  margin-left: -2.9658203125em;
  color: rgb(36, 57, 83);
}
.icon-logo-snofrisk:before {
  content: "\e926";
}

.icon-logo-jarlsberg {
	position: relative;
	overflow: visible;
	height: 50px;
}

.icon-logo-jarlsberg svg {
  margin: -30px auto 0;
  display: block;
  height: 120px;
  transition: all .25s ease;

  @media ($smartphone) {
  	height: 90px;
  	margin-top: -15px;
  }
}
.icon-video:before {
  content: "\e939";
}
.icon-pot-empty:before {
  content: "\e92a";
}
.icon-pinterest:before {
  content: "\e935";
}
.icon-instagram:before {
  content: "\e938";
}
.icon-bent-arrow:before {
  content: "\e900";
}
.icon-brunost-alt-logo .path1:before {
  content: "\e90d";
  color: rgb(255, 255, 255);
}
.icon-brunost-alt-logo .path2:before {
  content: "\e90e";
  margin-left: -1.63671875em;
  color: rgb(255, 255, 255);
}
.icon-brunost-alt-logo .path3:before {
  content: "\e90f";
  margin-left: -1.63671875em;
  color: rgb(216, 35, 50);
}
.icon-brunost-alt-logo .path4:before {
  content: "\e910";
  margin-left: -1.63671875em;
  color: rgb(7, 48, 93);
}
.icon-brunost-alt-logo .path5:before {
  content: "\e911";
  margin-left: -1.63671875em;
  color: rgb(7, 48, 93);
}
.icon-brunost-alt-logo .path6:before {
  content: "\e912";
  margin-left: -1.63671875em;
  color: rgb(7, 48, 93);
}
.icon-brunost-alt-logo .path7:before {
  content: "\e913";
  margin-left: -1.63671875em;
  color: rgb(7, 48, 93);
}
.icon-brunost-alt-logo .path8:before {
  content: "\e914";
  margin-left: -1.63671875em;
  color: rgb(7, 48, 93);
}
.icon-brunost-alt-logo .path9:before {
  content: "\e915";
  margin-left: -1.63671875em;
  color: rgb(255, 255, 255);
}
.icon-brunost-alt-logo .path10:before {
  content: "\e916";
  margin-left: -1.63671875em;
  color: rgb(255, 255, 255);
}
.icon-brunost-alt-logo .path11:before {
  content: "\e917";
  margin-left: -1.63671875em;
  color: rgb(255, 255, 255);
}
.icon-brunost-alt-logo .path12:before {
  content: "\e918";
  margin-left: -1.63671875em;
  color: rgb(255, 255, 255);
}
.icon-brunost-alt-logo .path13:before {
  content: "\e919";
  margin-left: -1.63671875em;
  color: rgb(255, 255, 255);
}
.icon-brunost-alt-logo .path14:before {
  content: "\e91a";
  margin-left: -1.63671875em;
  color: rgb(255, 255, 255);
}
.icon-brunost-alt-logo .path15:before {
  content: "\e91b";
  margin-left: -1.63671875em;
  color: rgb(255, 255, 255);
}
.icon-brunost-alt-logo .path16:before {
  content: "\e91c";
  margin-left: -1.63671875em;
  color: rgb(255, 255, 255);
}
.icon-brunost-alt-logo .path17:before {
  content: "\e91d";
  margin-left: -1.63671875em;
  color: rgb(255, 255, 255);
}
.icon-brunost-alt-logo .path18:before {
  content: "\e91e";
  margin-left: -1.63671875em;
  color: rgb(255, 255, 255);
}
.icon-brunost-alt-logo .path19:before {
  content: "\e91f";
  margin-left: -1.63671875em;
  color: rgb(255, 255, 255);
}
.icon-brunost-alt-logo .path20:before {
  content: "\e920";
  margin-left: -1.63671875em;
  color: rgb(255, 255, 255);
}
.icon-brunost-alt-logo .path21:before {
  content: "\e921";
  margin-left: -1.63671875em;
  color: rgb(255, 255, 255);
}
.icon-brunost-alt-logo .path22:before {
  content: "\e922";
  margin-left: -1.63671875em;
  color: rgb(255, 255, 255);
}
.icon-brunost-alt-logo .path23:before {
  content: "\e923";
  margin-left: -1.63671875em;
  color: rgb(255, 255, 255);
}
.icon-brunost-alt-logo .path24:before {
  content: "\e924";
  margin-left: -1.63671875em;
  color: rgb(255, 255, 255);
}
.icon-brunost-alt-logo .path25:before {
  content: "\e925";
  margin-left: -1.63671875em;
  color: rgb(255, 255, 255);
}
.icon-big-arrow-2:before {
  content: "\e927";
}
.icon-big-pin:before {
  content: "\e928";
}
.icon-pot-filled:before {
  content: "\e929";
}
.icon-arrow-left:before {
  content: "\e92b";
}
.icon-arrow-right:before {
  content: "\e92c";
}
.icon-burger:before {
  content: "\e92d";
}
.icon-cross:before {
  content: "\e92e";
}
.icon-facebook:before {
  content: "\e92f";
}
.icon-home:before {
  content: "\e930";
}
.icon-magnifier:before {
  content: "\e931";
}
.icon-minus:before {
  content: "\e932";
}
.icon-pin-frame:before {
  content: "\e933";
}
.icon-pin:before {
  content: "\e934";
}
.icon-small-arrow-down:before {
  content: "\e936";
}
.icon-plus:before {
  content: "\e937";
}

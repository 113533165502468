.mod-productslider {
  margin-bottom: 0;
  background-color: $white;
  color: #424242;
  padding: 0 0 0 0;
  &.imagery {
    .headline {
      color: $white;
      font-size: 64px;
      line-height: 64px;
      text-align: left;
      width: 50%;
      display: inline-block;
      @media ($smartphone) {
        width: 100%;
        font-size: 30px;
        line-height: 36px;
        text-align: center;
      }
    }
    .header {
      text-align: left;
      padding: 0;
      height: 540px;
      @media ($smartphone) {
        height: 300px;
      }
    }
    .shadow-bg {
      background-size: cover;
      height: 540px;
      @media ($smartphone) {
        height: 300px;
      }
    }
    .slider {
      .slider-viewport {
        .stripe {
          .slide {
            @media ($desktop) {
              > a {
                &.active {
                  .text {
                    opacity: 0;
                    visibility: hidden;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .header {
    padding: 55px 10px 150px 10px;
    @media ($smartphone) {
      padding: 30px 30px 150px 30px;
    }
  }
  .headline {
    font-size: 48px;
    line-height: 54px;
    text-align: center;
    margin: 0;
    font-weight: 300;
    //white-space: pre;
    @media ($smartphone) {
      font-size: 30px;
      line-height: 36px;
      margin-bottom: 0;
    }
  }

  .shadow-bg {
    background-repeat: repeat-x;
    background-image: url(../images/shadow-bg.png);
    //background-position: center bottom;
    background-position: center 35%;
    position: relative;
    transition: background-image .25s ease;
  }

  .tone {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,.15);
  }

  .nest {
    position: relative;
  }

  .slider {
    margin-top: -135px;
    position: relative;
    opacity: 0;
    transition: opacity .25s ease;
    @media ($smartphone) {
      margin-top: -150px;
    }
    .slider-viewport {
      overflow: hidden;
      padding: 50px 0 50px 0;
      text-align: center;
      @media ($smartphone) {
        margin: 0 -50px;
        padding-top: 40px;
        height: 300px;
      }
      .stripe {
        width: 100%;
        display: inline-block;
        // @media ($smartphone) {
        //   width: 600%;
        // }
        .slide {
          padding: 0;
          > a {
            width: 100%;
            //transition: transform .25s ease;
            &:hover {
              img {
                transform: scale(1.1, 1.1);
                //margin-top: 20px;
                @media ($smartphone) {
                  transform: scale(1, 1);
                  margin-top: 0;
                }
              }
            }
            &.active {
              img {
                transform: scale(1.6, 1.6);
                //margin-top: 20px;
                @media ($smartphone) {
                  transform: scale(1, 1);
                  margin-top: 0;
                }
              }
              .text {
                transform: translateY(20px);
                @media ($smartphone) {
                  transform: translateY(0px);
                }
              }
            }
            @media ($smartphone) {
              padding: 0;
              transition: transform .5s ease,
                          margin-top .5s ease;
              &.previous {
                margin-top: 20px;
                transform: rotate(-10deg);
              }
              &.next {
                margin-top: 20px;
                transform: rotate(10deg);
              }
              &.previous-us {
                margin-top: 40px;
                transform: rotate(-20deg);
              }
              &.next-xt {
                margin-top: 40px;
                transform: rotate(20deg);
              }
            }
          }
          .image {
            text-align: center;
            img {
              max-height: 130px;
              width: auto;
              transform-origin: 50% 50%;
              transition: transform .25s ease;
            }
          }
          .text {
            color: #5F5F5F;
            padding: 15px;
            text-align: center;
            transition: opacity .25s ease,
                        transform .25s ease;
            @media ($smartphone) {
              padding: 5px 10px 0 10px;
            }
            p {
              font-size: 16px;
              line-height: 22px;
              margin: 0;
            }
          }
        }
      }
    }
    .slider-arrow {
      @media ($smartphone) {
        display: none;
      }
    }
  }
}
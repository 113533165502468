.video {
  position: relative;
  height: 100%;
  width: 100%;
}

.youtube-video__iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.mod-topimage {
  margin-bottom: 0;
  height: 700px;
  position: relative;
  background-size: cover;
  background-position: center center;
  overflow: hidden;
  transition: height .25s ease,
              background-image .75s ease;

  .tone {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,.15);
  }
  .nest {
    position: relative;
    height: 100%;
    > .text-center {
      width: 100%;
      > .image {
        width: 40%;
        @media ($smartphone) {
          width: 100%;
        }
      }
      @media ($smartphone) {
        width: 90%;
      }
    }
  }
  .text-content {
    color: $white;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 50px;
    font-weight: 400;
    @media ($smartphone) {
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 25px;
      br {
        display: none;
      }
    }
  }
  .text {
    color: $white;
    /* font-size: 36px; */
    line-height: 42px;
    margin-bottom: 15px;
    padding: 0 25px;
    @media ($smartphone) {
      /* font-size: 24px; */
      line-height: 30px;
      margin-bottom: 40px;
    }
    h1, h2, h3, h4, h5, h6 {
      font-size: 72px;
      line-height: 80px;
      margin: 0;
      @media ($tablet) {
        font-size: 56px;
        line-height: 60px;
      }
      @media ($smartphone) {
        font-size: 24px;
        line-height: 30px;
      }
    }
  }
  h1 {
    &.text {
      font-size: 46px;
      line-height: 50px;
      @media ($smartphone) {
        font-size: 30px;
        line-height: 30px;
        padding: 0;
        margin-bottom: 15px;
        br {
          display: none;
        }
      }
    }
  }
  .image {
    width: 100%;
    @include inline();
    img {
      width: 80%;
      @media ($smartphone) {
        max-width: 230px;
      }
    }
  }
  .go-down-indicator {
    height: 70px;
    display: block;
    @media ($smartphone) {
      display: none;
    }
  }
  &.parent-article {
    height: 540px;
    .nest {
      > .text-center {
        width: auto;
      }
    }
    @media ($smartphone) {
      height: 300px;
    }
  }
  &.parent-recipe {
    margin-bottom: 0;
    height: 540px;
    background-size: cover;
    background-position: center center;
    overflow: hidden;
    .nest {
      > .text-center {
        width: auto;
      }
    }
    @media ($smartphone) {
      height: 300px;
    }
    .nest {
      height: 100%;
    }
    .text {
      color: $white;
      margin-bottom: 100px;
      padding: 0 25px;
    }
    .image {
      margin-bottom: 35px;
      max-width: 260px;
      @include inline();
    }
  }
}

.site-brunost {
  &.page-brunost {
    .mod-topimage {
      background-position: bottom center;
      .text-content,
      .text {
        text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
      }
    }
  }

  .mod-topimage {
    h1.text {
      font-weight: 600;
    }
  }
}

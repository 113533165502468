.mod-slider {
  background-size: cover;
  background-position: center center;
  background-image: none;
  &.search {
    .slider {
      .slider-viewport,
      .slider-arrow,
      .slider-navigation {
        opacity: 0;
        visibility: hidden;
      }
      .category-headline,
      .btn:not(.blue.filled) {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  .slider {
    position: relative;
    opacity: 0;
    .tone {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0,0,0,.15);
    }
    .category-headline {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0;
      visibility: hidden;
      transition: opacity .25s ease;
      .inner {
        position: relative;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
      .tone {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0,0,0,.15);
      }
      h1, h2, h3, h4, h5, h6 {
        font-size: 72px;
        line-height: 80px;
        color: $white;
        text-align: center;
        padding: 70px 0 0 0;
        @media ($tablet) {
          font-size: 56px;
          line-height: 60px;
        }
        @media ($smartphone) {
          font-size: 30px;
          line-height: 34px;
          padding-top: 60px;
        }
      }
    }
    .btn {
      position: absolute;
      top: 20px;
      left: 20px;
      z-index: 999;
      padding-left: 15px;
      padding-right: 15px;
      opacity: 0;
      visibility: hidden;
      transition: opacity .25s ease;
      span {
        display: inline-block;
      }
      .icon {
        vertical-align: top;
        margin: 0 5px 0 0;
      }
      @media ($smartphone) {
        left: 10px;
        top: 29px;
        padding: 8px 10px;
      }
    }
    .slider-viewport {
      overflow: hidden;
      opacity: 1;
      visibility: visible;
      transition: opacity .25s ease;
      .stripe {
        width: 300%;
        .slide {
          padding: 0;
          position: relative;
          .background-image {
            position: absolute;
            background-size: auto;
            background-position: center center;
            background-repeat: no-repeat;
            top: 0%;
            right: 0%;
            bottom: 0%;
            left: 0%;
            @media ($smartphone) {
              background-size: cover;
            }
          }
          > .ws-12 {
            position: relative;
          }
          .image {
            text-align: center;
            height: 540px;
            img {
              width: 100%;
              opacity: 0;
            }
            @media ($smartphone) {
              height: 250px;
            }
          }
          .text {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            color: $white;
            text-align: center;
            p,
            h1, h2, h3, h4, h5, h6 {
              font-size: 68px;
              line-height: 76px;
              margin: 0;
              text-align: center;
              font-weight: 400;
              font-family: 'DIN Next', Arial;
              sup {
                font-size: 50%;
              }
              @media ($tablet) {
                font-size: 56px;
                line-height: 60px;
              }
              @media ($smartphone) {
                padding-top: 40px;
                font-size: 30px;
                line-height: 34px;
              }
            }
          }
        }
      }
    }
    .slider-navigation {
      position: absolute;
      bottom: 25px;
      left: 0;
      width: 100%;
      @media ($smartphone) {
        bottom: 5px;
      }
      li {
        margin: 0 5px;
        @media ($smartphone) {
          margin: 0 2.5px;
        }
      }
      a {
        display: block;
        width: 20px;
        height: 20px;
        background-color: #FFFFFF;
        transition: background-color .15s ease;
        border-radius: 50%;
        &:hover,
        &.active {
          background-color: $blue;
          @at-root {
            .site-jarlsberg & {
              background-color: $jarlsberg-blue;
            }
          }
        }
        @media ($smartphone) {
          width: 10px;
          height: 10px;
        }
      }
    }
    .slider-arrow {
      &.left,
      &.right {
        margin-top: -28px;
        .icon {
          @media ($tablet) {
            font-size: 20px;
            line-height: 20px;
          }
        }
      }
      &.left {
        left: 20px;
      }
      &.right {
        right: 20px;
      }
      @media ($smartphone) {
        display: none;
      }
    }
  }
}

.site-brunost {
  .mod-slider {
    .slider {
      .slider-viewport {
        .stripe {
          .slide {
            .text {
              h1, h2, h3, h4, h5, h6 {
                font-family: 'Sanchez', Arial;
              }
            }
          }
        }
      }
    }
  }
}

.site-jarlsberg {
  .mod-slider {
    .slider {
      .slider-viewport {
        .stripe {
          .slide {
            .text {
              h1,
              h2,
              h3,
              h4,
              h5,
              h6 {
                font-family: $jarlsberg-header-font;
              }
            }
          }
        }
      }
    }
  }
}

.article-push	{
	width: 100%;
	height: 390px;
	padding: 10px;
	@media ($smartphone) {
		padding: 20px 0 0 0;
	}

	&.items-1	{
		.mod-card	{
			width: 100%;
		}
	}
	&.items-2	{
		.mod-card	{
			width: 50%;
			float: left;
			&:nth-of-type(1) {
				padding-right: 5px;
			}
			&:nth-of-type(2) {
				padding-left: 5px;
			}
		}
	}
	&.items-3	{
		.mod-card	{
			width: 33%;
			display: inline-block;
			&:nth-of-type(1) {
				padding-right: 5px;
			}
			&:nth-of-type(2) {
				padding-left: 5px;
				padding-right: 5px;
			}
			&:nth-of-type(3) {
				padding-left: 5px;
			}
		}
	}

	@media ($smartphone) {
		&.items-2,
		&.items-3	{
			.mod-card	{
				width: 100%;
				&:nth-of-type(1),
				&:nth-of-type(2),
				&:nth-of-type(3)	{
					padding-left: 0;
					padding-right: 0;
				}
			}
		}
	}

	.article	{
		position: relative;
		float: left;
		height: 100%;

		.article-push-content	{
			width: 100%;
			height: 100%;

			figure	{
				background-size: cover;
				background-position: center center;
			}
		}

		&.l-image	{
			figure	{
				width: 100%;
				height: 100%;
			}

			.article-text	{
				position: absolute;
				left: 43px;
				bottom: 40px;
			}
		}

		&.m-image	{
			figure	{
				display: inline-block;
				vertical-align: middle;
				width: 49%;
				height: 320px;
				margin: 20px 64px 20px 0;
			}
			.article-text	{
				position: relative;
				display: inline-block;
				vertical-align: middle;
			}
		}

		&.s-image	{
			figure	{
				display: inline-block;
				vertical-align: middle;
				width: 29.296875%;
				height: 260px;
				margin: 48px 63px 52px 43px; 
			}
			.article-text	{
				position: relative;
				display: inline-block;
				vertical-align: middle;
			}
		}
	}
}
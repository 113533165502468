.page-admin	{

	font-family: 'Open Sans', sans-serif;

	.search	{
		display: block;
		width: 100%;
		max-width: 600px;
		margin: 10px auto;
		padding: 10px 0;
		input	{
			display: inline-block;
			vertical-align: middle;
			width: 70%;
		}
		.button	{
			display: inline-block;
			vertical-align: middle;
			width: 28%;
		}

	}
	.details	{
		width: 100%;
		max-width: 600px;
		margin: 0 auto;

		.field	{
			margin: 10px 0;
			padding: 10px 0;
			border-bottom: 1px solid #ccc;
			label	{
				display: inline-block;
				vertical-align: middle;

				font-weight: 700;
				width: 30%;
			}
			input	{
				display: inline-block;
				vertical-align: middle;
				width: 70%;
			}

			&.empty	{
				opacity: 0.3;
			}
		}
	}

	input[type="text"] {
		font-size: 1em;
		padding: 10px;
	}

	.button	{
		max-width: 170px;
		background-color: #999999;
		box-sizing: border-box;
		padding: 1px 19px 1px 19px;
		margin: 0;
		cursor: pointer;
		border: 1px solid transparent;
		outline: none;
		padding: 8px 19px;
		line-height: 24px;
		font-size: 14px;
		color: #fff;
		font-weight: 800;

		&:hover	{
			opacity: 0.9;
		}
	}
}
.mod-recipelist {
  margin-bottom: 0;
  background-color: $white;
  padding-bottom: 50px;
  padding-top: 55px;
  opacity: 0;
  transition: opacity .25s ease;

  &.recipe-search-result {
    padding-bottom: 0;
    display: block;

    .header {
      display: none;
    }
  }

  &.hidden {
    display: none;
  }
  @media ($smartphone) {
    padding-bottom: 30px;
    padding-top: 20px;
  }
  &.search {
    padding-top: 0;
    .header {
      text-align: center;
    }
  }
  &.dark {
    background-color: #E0E0E0;
    .header {
      .headline {
        color: #424242;
      }
      .tag {
        color: #424242;
      }
    }
  }
  .nest {
    > .crow {
      text-align: left;
    }
  }
  .crow {
    > div {
      display: none;
      opacity: 0;
      visibility: hidden;
      transition: opacity .25s ease;
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        display: inline-block;
        opacity: 1;
        visibility: visible;
      }
      &.show {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  .header {
    margin-bottom: 20px;
    .headline {
      font-size: 64px;
      line-height: 70px;
      color: #4A4A4A;
      padding-bottom: 20px;
      margin: 0;
      @media ($smartphone) {
        font-size: 24px;
        line-height: 32px;
        padding-bottom: 0px;
      }
    }
    .tag {
      font-size: 24px;
      line-height: 32px;
      color: #4A4A4A;
      text-transform: uppercase;
      @media ($smartphone) {
        font-size: 18px;
        line-height: 22px;
      }
      b {
        font-weight: 500;
      }
    }
  }
  .crow {
    > div {
      @media ($smartphone) {
        width: 50% !important;
      }
    }
  }
  .ws-3 {
    @media ($smartphone) {
      width: 50%;
    }
  }
  .ws-4 {
    @media ($smartphone) {
      width: 50%;
    }
  }
}

$auto-widths-iterations: 30; /* Set to how many columns you want enable for auto fly operation */
$percent-widths-iterations: 100; /* Set to percentages, defaults to 1,2,3,4,5 till 100 */
$unit-widths-iterations: 12; /* Set to the number of spans you want, default 12 */
$gutter: 10; /* Set to what gutter you want crow to use, to remove gutter use class "no-gutter", don't set this value to 0 */

*,
*:before,
*:after {
  box-sizing: inherit;
}
html {
  box-sizing: border-box
}

@mixin auto-widths($auto-widths-iterations) {
  @for $i from 1 through $auto-widths-iterations {
    > *:first-child:nth-last-child(#{$i}),
    > *:first-child:nth-last-child(#{$i}) ~ * {
      $percent : calc(100 / #{$i} * 1%);
      width: $percent;
    }
  }
}

@mixin percent-widths($percent-widths-iterations) {
  @for $i from 1 through $percent-widths-iterations {
    .w-#{$i} {
      $percent : calc($i * 1%);
      width: $percent;
    }
  }
}
@mixin unit-widths($unit-widths-iterations) {
  @for $i from 1 through $unit-widths-iterations {
    .ws-#{$i} {
      $percent: calc(100 * #{$i / $unit-widths-iterations} * 1%); // * 1% to get number as percentage
      width: $percent;
    }
  }
}

@mixin crow {
  text-align: center;
  font-size: 0;
  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
  > * {
    vertical-align: middle;
    display: inline-block;
    font-size: medium; /* IE */
    font-size: initial;
    text-align: left;
    margin: 0;
    padding: 0;
  }
}

.crow {
  padding: 0 ($gutter*1px);
  @include crow();
  @include percent-widths($percent-widths-iterations);
  @include unit-widths($unit-widths-iterations);
  &.fly {
    @include auto-widths($auto-widths-iterations);
  }
  .crow {
    font-size: 0;
  }
  > * {
    padding: 0 ($gutter*1px);
    &.up {
      vertical-align: top;
    }
    &.down {
      vertical-align: bottom;
    }
  }
  &.up {
    > * {
      vertical-align: top;
    }
  }
  &.down {
    > * {
      vertical-align: bottom;
    }
  }
  &.no-gutter {
    padding: 0;
    > * {
      padding: 0;
    }
  }
}
.nest {
  width: 1024px;
  margin: 0 auto;
  padding: 0 ($gutter*1px);
  > .crow {
    margin: 0 (-$gutter*1px);
    padding: 0;
  }
}
@mixin uncrow {
  &.crow {
    height: auto;
    > * {
      display: block;
      width: 100%;
    }
    &:before {
      content: '';
      display: none;
    }
  }
}
.mod-fullwidthimage {
  margin-bottom: 0;
  background-size: cover;
  background-position: center center;
  color: $white;
  height: 475px;
  @media ($smartphone) {
    height: 300px;
  }
  .text {
    text-align: center;
    padding: 0 20px;
    .headline {
      font-size: 90px;
      line-height: 110px;
      padding-bottom: 10px;
      font-weight: 400;
      font-family: 'DIN Next';
      @media ($smartphone) {
        font-size: 30px;
        line-height: 40px;
      }
    }
    .text {
      font-size: 36px;
      line-height: 44px;
      font-family: 'Open Sans', Arial;
      @media ($smartphone) {
        font-size: 18px;
        line-height: 22px;
        br {
          display: none;
        }
      }
    }
  }
}

.site-brunost {
  .mod-fullwidthimage {
    .text {
      .headline {
        font-family: 'Sanchez', Arial;
      }
    }
  }
}

.site-jarlsberg {
	.mod-fullwidthimage .text {
	  .headline {
		  font-family: $jarlsberg-header-font;
	  }
  }
}

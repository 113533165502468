.bot {
  .fullwidth {
    color: #4A4A4A;
    padding: 40px 0;
    font-size: 18px;
    line-height: 22px;
    font-family: 'Open Sans', Arial;
    &.remove {
      display: none !important;
    }
    .nest {
      //width: 100%;
      @media ($tablet) {
        width: auto;
      }
    }
    .text-logo {
      font-size: 48px;
      line-height: 48px;
      @media ($tablet) {
        font-size: 40px;
        line-height: 40px;
      }
    }
    ul {
      margin-top: 10px;
      li {
        margin: 4px 5px;
        a {
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    .icon {
      &.icon-logo-snofrisk {
        color: #4A4A4A;
        font-size: 36px;
        line-height: 36px;
      }
    }
    @media ($smartphone) {
      > .crow {
        @include uncrow();
      }
      .text-left {
        text-align: center;
      }
      .ws-6 {
        padding: 0;
      }
      .text {
        padding: 30px 15px 40px 15px;
        li {
          text-align: center;
        }
        br {
          display: none;
        }
      }
    }
  }
}

.mod-instagram-feed	{
	background-color: #fff;
	.header {
		margin-bottom: 20px;
		.headline {
			font-size: 64px;
			line-height: 70px;
			color: #4A4A4A;
			padding-bottom: 20px;
			margin: 0;
			@media ($smartphone) {
				font-size: 24px;
				line-height: 32px;
				padding-bottom: 0px;
			}
		}
		.tag {
			font-size: 24px;
			line-height: 32px;
			color: #4A4A4A;
			text-transform: uppercase;
			@media ($smartphone) {
				font-size: 18px;
				line-height: 22px;
			}
			b {
				font-weight: 500;
			}
		}
	}

	.items	{
		margin-bottom: 25px;
		.item	{
			width: 25%;
			margin: 0;
			a	{
				padding: 0;
				margin-bottom: 0;
			}

			.text	{
				overflow: hidden;
				padding: 10px 20px;
				height: 9em;
				font-size: 13px;
				line-height: 1.4em;
			}
			@media ($smartphone)	{
				width: 50%;
				.text	{
					height: 11em;
				}
			}
			@media ($tablet)	{
				width: 33.3%;
			}
		}
	}

	.load-more	{
		padding-bottom: 50px;
	}
}
.mod-googlemap {
  .mapboxgl-ctrl-bottom-left  {
    bottom: 14px;
    left: auto;
    right: 4px;

    @media ($smartphone) {
      bottom: 4px;
      right: 36px;
    }
  }
  .mapboxgl-popup {
    width: 200px;
    height: 278px;

    .mapboxgl-popup-tip {
      display: none;
    }
    .mapboxgl-popup-content {
        background: transparent;
        box-shadow: none;
        font-family: $font-family;
        width: 100%;
        height: 100%;
        position: relative;
        padding: 15px 15px 0;

        svg#box-shape {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          z-index: -1;
          filter: drop-shadow(0 0 9px rgba(0,0,0,0.25));
          path  {
            fill: #f7f7f7;
          }
        }

        .marker-cross {
          position: absolute;
          top: -12px;
          right: -12px;
          width: 30px;
          height: 30px;
          background-color: #82c9e6;
          border-radius: 50%;
          text-align: center;
          cursor: pointer;
          box-shadow: 1px 1px 7px rgba(0,0,0,.15);
          .icon {
            color: $white;
            font-size: 12px;
            line-height: 12px;
          }
          &:hover {
            background-color: $white;
            
            .icon {
              color: #82C9E6;
            }
          }
         

        }

        .mapboxgl-popup-close-button {
          appearance: none;
          position: absolute;
          top: -12px;
          right: -12px;
          width: 30px;
          height: 30px;
          font-size: 20px;
          padding: 0;
          background-color: #82C9E6;
          border-radius: 50%;
          text-align: center;
          box-shadow: 1px 1px 7px rgba(0,0,0,.15);
          color: $white;
          cursor: pointer;
          border: none;
          outline: none;
          &:hover {
            background-color: $white;
            color: #82C9E6;
          }
        }

        .marker-place {
          font-weight: 400;
          font-size: 22px;
          color: #4A4A4A;
          padding-bottom: 10px;
          line-height: 1.25em;
          font-family: 'DIN Next', Arial;
        }
        .marker-address,
        .marker-open {
          font-size: 14px;
          line-height: 18px;
          color: #4A4A4A;
          font-weight: 300;
          letter-spacing: .5px;
        }
        hr {
          display: block;
          margin: 20px 0 10px 0;
          padding: 0;
          border-bottom: 1.5px solid #E0E0E0;
          width: 100%;
        }
        a {
          display: block;
        }
        .marker-directions {
          span {
            color: #4A4A4A;
            font-size: 14px;
            line-height: 18px;
          }
          .icon {
            margin-left: 20px;
            color: #00BBF5;
            font-size: 20px;
            line-height: 20px;
          }
        }
      }
  }

  .map-toggle,
  .zoom-in,
  .zoom-out {
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: 50px;
    height: 50px;
    text-align: center;
    box-shadow: 1px 1px 5px rgba(0,0,0,.15);
    background-color: darken(#82C9E6, 25%);
    border-radius: 50%;
    transition: background-color .25s ease;
    @media ($smartphone) {
      top: 55px;
      left: 15px;
      z-index: 100;
    }
    .icon {
      color: $white;
      font-size: 22px;
      line-height: 22px;
    }
    &:hover {
      background-color: darken(#82C9E6, 20%);
    }
    &:active,
    &.active {
      background-color: $white !important;
      .icon {
        color: darken(#82C9E6, 25%);
      }
    }
  }
  .zoom-in,
  .zoom-out {
    position: absolute;
    left: 23px;
    width: 44px;
    height: 44px;
    bottom: 80px;
    &:active {
      background-color: darken(#82C9E6, 20%) !important;
      .icon {
        color: $white;
      }
    }
    @media ($smartphone) {
      top: 159px;
      left: 18px;
    }
  }
  .zoom-out {
    bottom: 135px;
    .icon {
      font-size: 4px;
      line-height: 4px;
    }
    @media ($smartphone) {
      top: 110px;
      left: 18px;
    }
  }
  .search-box {
    z-index: 100;
    position: relative;
    width: 350px;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: opacity .25s ease;
    &.active {
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
    }
    @media ($smartphone) {
      width: 90%;
      vertical-align: bottom;
      padding-bottom: 30px !important;
    }
    .input {
      background-color: #F6F6F6;
      height: 50px;
      box-shadow: 1px 1px 5px rgba(0,0,0,.15);
      overflow: hidden;
      border-radius: 5px;
      position: relative;
      form {
        height: 100%;
        width: 100%;
      }
      input {
        font-size: 20px;
        line-height: 20px;
        color: #424242;
        border: 0;
        outline: 0;
        width: 100%;
        background-color: transparent;
        height: 100%;
        padding: 0 70px 0 20px;
      }
    }
    &.searching {
      .input {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        @media ($smartphone) {
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
          border-top-left-radius: 0px;
          border-top-right-radius: 0px;
        }
      }
      .search-results {
        opacity: 1;
        visibility: visible;
      }
    }
    .magnifier {
      position: absolute;
      top: 0;
      right: 0;
      height: 50px;
      width: 50px;
      .line {
        position: absolute;
        left: 0;
        top: 5px;
        bottom: 5px;
        border-left: 1px solid #E0E0E0;
      }
    }
    .headline {
      position: absolute;
      left: 0;
      top: -70px;
      width: 100%;
      text-align: center;
      font-size: 36px;
      line-height: 40px;
      color: #4A4A4A;
    }
    .search-results {
      box-shadow: 1px 4px 5px rgba(0,0,0,.15);
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      overflow: hidden;
      position: absolute;
      top: 100%;
      width: 100%;
      opacity: 0;
      visibility: hidden;
      transition: opacity .25s ease;
      @media ($smartphone) {
        bottom: 100%;
        top: auto;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }
      li {
        &:first-child {
          a {
            border-color: transparent;
            @media ($smartphone) {
              border-top: 1px solid #E0E0E0;
            }
          }
        }
        &:last-child {
          a {
            @media ($smartphone) {
              border-bottom: 1px solid #E0E0E0;
            }
          }
        }
        a,
        .span {
          border-top: 1px solid #E0E0E0;
          padding: 10px;
          display: block;
          color: #4A4A4A;
          background-color: #F6F6F6;
          font-size: 14px;
          line-height: 18px;
          transition: background-color .15s ease;
        }
        a {
          &:hover {
            background-color: darken(#F6F6F6, 5%);
          }
        }
      }
    }
  }

  #marker,
  .mapboxgl-marker {
    position: relative;
    transform: translateZ(0);
    .marker-box {
      position: absolute;
      top: -56px;
      top: -66px;
      left: -28px;
      background-color: transparent;
      background-image: url('../images/heart-pin-bg.png');
      background-repeat: no-repeat;
      background-position: top center;
      width: 43px;
      height: 63px;
      border-radius: 56px;
      //box-shadow: 1px 1px 7px rgba(0,0,0,.15);
      transition: background-color .25s ease,
                  background-image .25s ease,
                  transform .25s ease,
                  top .25s ease,
                  left .25s ease,
                  width .25s ease,
                  height .25s ease;
      span {
        &.icon-pin-heart {
          font-size: 24px;
          line-height: 24px;
          display: inline-block;
          .path1,
          .path2,
          .path3 {
            &:before {
              transition: color .25s ease;
            }
          }
        }
      }
      .marker-triangle {
        position: absolute;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-top: 10px solid #C02A37;
        width: 0;
        height: 0;
        top: 100%;
        left: 50%;
        margin-left: -10px;
      }
      .marker-cross {
        position: absolute;
        top: -10px;
        right: -10px;
        width: 30px;
        height: 30px;
        background-color: #82C9E6;
        border-radius: 50%;
        text-align: center;
        opacity: 0;
        visibility: hidden;
        transition: opacity .25s ease;
        box-shadow: 1px 1px 7px rgba(0,0,0,.15);
        .icon {
          color: $white;
          font-size: 12px;
          line-height: 12px;
        }
        &:hover {
          cursor: pointer;
        }
      }
      .marker-splash {
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -21.5px;
        width: 43px;
        height: 18px;
        background-image: url(../images/heart-pin-bg-splash.png);
        background-repeat: no-repeat;
        background-position: top center;
        opacity: 0;
      }
      .marker-content {
        opacity: 0;
        visibility: hidden;
        transition: opacity .5s ease;
        transition-delay: .25s;
        padding: 15px;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        position: relative;
        .marker-place {
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
          color: #4A4A4A;
          padding-bottom: 10px;
        }
        .marker-address,
        .marker-open {
          font-size: 14px;
          line-height: 18px;
          color: #4A4A4A;
          font-weight: 300;
          letter-spacing: .5px;
        }
        hr {
          display: block;
          margin: 20px 0 10px 0;
          padding: 0;
          border-bottom: 1.5px solid #E0E0E0;
          width: 100%;
        }
        a {
          display: block;
        }
        .marker-directions {
          span {
            color: #4A4A4A;
            font-size: 14px;
            line-height: 18px;
          }
          .icon {
            margin-left: 20px;
            color: #00BBF5;
            font-size: 20px;
            line-height: 20px;
          }
        }
      }
    }
    &:hover:not(.open) {
      .marker-box {
        cursor: pointer;
        //background-color: #E3272B;
        transform: translateY(-5px);
        background-image: url("../images/heart-pin-bg-hover.png");
        .icon-pin-heart {
          .path1 {
            &:before {
              color: #FF5600;
            }
          }
          .path2,
          .path3 {
            &:before {
              color: #C30E1E;
            }
          }
        }
      }
    }
    &.open {
      z-index: 1;
      .marker-box {
        width: 250px;
        height: 200px;
        left: -125px;
        top: -210px;
        background-color: #F7F7F7;
        transform: tranlateY(0);
        border-radius: 10px;
        background-image: none;
      }
      .marker-splash {
        opacity: 1;
        transition: opacity .25s ease;
      }
      .icon-pin-heart {
        display: none !important;
      }
      .marker-cross {
        opacity: 1;
        visibility: visible;
      }
      .marker-content {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
.mod-thumbnailslider {
  margin-bottom: 0;
  padding: 55px 0;
  &.blue {
    background-color: #002B45;
    color: $white;
    .headline {
      color: $white;
    }
  }
  &.dark {
    background-color: #424242;
    color: $white;
    .headline {
      color: $white;
    }
    .slider {
      .text {
        color: $white;
      }
    }
  }
  &.bright {
    background-color: #F9F8F6;
    color: #4A4A4A;
    .headline {
      color: $black;
    }
    .slider {
      .text {
        color: #4A4A4A;
      }
    }
  }
  .header {
    padding: 0 0 55px 10px;
    @media ($smartphone) {
      padding-bottom: 30px;
    }
  }
  .head-tag {
    font-size: 24px;
    line-height: 32px;
    text-transform: uppercase;
    @media ($smartphone) {
      font-size: 16px;
      line-height: 22px;
    }
  }
  .headline {
    font-size: 48px;
    line-height: 54px;
    font-weight: 300;
    margin: 0;
    font-family: 'DIN Next', arial;
    @media ($smartphone) {
      font-size: 24px;
      line-height: 30px;
    }
  }

  .slider {
    position: relative;
    opacity: 0;
    .slider-viewport {
      overflow: hidden;
      .stripe {
        width: 100%;
        @media ($smartphone) {
          width: 400%;
        }
        .slide {
          padding: 0;
          .text {
            padding: 15px;
            .tag {
              font-size: 14px;
              line-height: 14px;
            }
            p {
              font-size: 18px;
              line-height: 24px;
              margin: 0;
            }
          }
        }
      }
    }
  }
}

.site-brunost {
  .mod-thumbnailslider {
    .headline {
      font-family: 'Sanchez', Arial;
    }
  }
}

.site-jarlsberg {
	.mod-thumbnailslider {
		.headline {
			font-family: $jarlsberg-header-font;
		}

		.head-tag {
			font-family: $jarlsberg-headline-font;
		}

		&.dark {
			background-color: $jarlsberg-dark;
		}
		&.bright {
			background-color: $jarlsberg-bright;
		}
	}
}

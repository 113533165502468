@mixin termly-tablet {
   @media all and (min-width: 768px) {
      @content;
   }
}

@mixin termly-desktop {
   @media all and (min-width: 1024px) {
      @content;
   }
}

.termly {
   &__container {
      padding: 42px 0;
   }

   &__text {
      width: 100%;
      text-align: center;
      line-height: 1.5;

      &--not-set {
         color: #d6001c; // TINE $seconday-color
      }

      &--edit {
         font-size: 1.5rem;
      }
   }
}

.page-404 {
  .fullscreen { 
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-position: center center;
  }
  .icon {
    font-size: 60px;
    line-height: 60px;
    &.icon-logo-brunost {
      .path19,
      .path20,
      .path21,
      .path22,
      .path23,
      .path24,
      .path25,
      .path26,
      .path27,
      .path28,
      .path29,
      .path30,
      .path31,
      .path32,
      .path33,
      .path34,
      .path35 {
        &:before {
          color: #fff;
        }
      }
    }
  }
  h1 {
    font-size: 70px;
    line-height: 78px;
    color: $white;
    text-shadow: 1px 1px rgba(0,0,0,.25);
    margin-bottom: 20px;
    @media ($tablet) {
      font-size: 50px;
      line-height: 56px;
    }
    @media ($smartphone) {
      font-size: 30px;
      line-height: 34px;
    }
  }
  p {
    font-size: 30px;
    line-height: 34px;
    color: $white;
    font-weight: 400;
    text-shadow: 1px 1px rgba(0,0,0,.25);
    &:hover {}
    .icon {
      font-size: 34px;
    }
    .text-label {
      display: inline-block;
      position: relative;
      .line {
        position: absolute;
        box-shadow: 1px 1px 1px rgba(0,0,0,.25);
        width: 100%;
        bottom: 0px;
        width: 100%;
        left: 0px;
        height: 2px;
        background-color: $white;
        display: inline-block;
      }
      span {
        position: relative;
      }
      &:hover {
        .line {
          display: none;
        }
      }
    }
    @media ($smartphone) {
      font-size: 18px;
      line-height: 22px;
    }
  }
}
.mod-article {
  margin-bottom: 0;
  background-color: $white;
  h1, h2, h3, h4, h5, h6 {
    color: #4A4A4A;
    padding: 20px 0 10px 0;
    margin: 0;
  }
  h1 {
    font-size: 72px;
    line-height: 78px;
  }
  h2 {
    font-size: 64px;
    line-height: 70px;
  }
  h3 {
    font-size: 58px;
    line-height: 64px;
  }
  h4 {
    font-size: 50px;
    line-height: 56px;
  }
  h5 {
    font-size: 42px;
    line-height: 48px;
  }
  h6 {
    font-size: 34px;
    line-height: 40px;
  }
  h1, h2, h3, h4, h5, h6 {
    @media ($smartphone) {
      font-size: 30px;
      line-height: 36px;
      padding-top: 5px;
    }
  }
  .ws-1 {
    @media ($smartphone) {
      display: none;
    }
  }
  .ws-10 {
    @media ($smartphone) {
      width: 100%;
    }
  }
  .text-content {
    padding-top: 40px;
    @media ($smartphone) {
      padding-top: 25px;
    }
  }

  .article-image {
    &--align-left {
      float: left;
      margin: 0 35px 35px 0;
    }

    &--align-right {
      float: right;
      margin: 0 0 35px 35px;
    }

    &--size-normal { width: 50%; }
    &--size-small { width: 35%; }

    @media ($smartphone) {
      margin: 0 0 25px 0 !important;
      width: 100% !important;
    }

    img {
      object-fit: contain;

      @media ($smartphone) {
        max-height: 300px;
      }
    }

    &__text {
      padding-top: 1rem;
      display: inline-block;

      &--center { text-align:center; }
    }
  }

  .device-adjustor {
    h1 {
      font-size: 72px;
      line-height: 82px;
      text-transform: uppercase;
      text-align: center;
      padding: 60px 0 10px 0;
      margin: 0;
    }
    @media ($smartphone) {
      height: 1px;
      position: relative;
    }
    h1 {
      @media ($smartphone) {
        font-size: 30px;
        line-height: 36px;
        color: $white;
        text-align: left;
        padding-top: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding-bottom: 30px;
      }
    }
  }
  ul,
  ol {
    padding-left: 15px;
    li {
      font-size: 18px;
      line-height: 24px;
      color: #5F5F5F;
    }
  }
  p {
    font-size: 18px;
    line-height: 24px;
    color: #5F5F5F;
    margin: 0 0 35px 0;
    @media ($smartphone) {
      margin: 0 0 25px 0;
      font-size: 16px;
      line-height: 24px;
    }
  }
  .image,
  figure {
    padding: 20px 20px 20px 20px;
    //width: 45% !important;
    margin: 0 !important;
    @media ($smartphone) {
      float: none !important;
      width: 100% !important;
      padding: 0 0 20px 0;
    }
  }
  q,
  blockquote p {
    font-size: 24px;
    line-height: 30px;
    color: #5F5F5F;
    width: 80%;
    margin: 0 auto;
    display: block;
    text-align: center;
    font-weight: 400;
    @media ($smartphone) {
      padding-left: 20px;
      font-size: 20px;
      line-height: 24px;
      text-align: left;
      border-left: 2px solid #FF0000;
      width: 100%;
    }
  }
  blockquote {
    @media ($smartphone) {
      margin-left: 0;
      margin-right: 0;
    }
  }
  .quoter {
    color: #5F5F5F;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    padding-top: 35px;
    text-align: center;
    @media ($smartphone) {
      display: none;
    }
  }
  .share-box {
    padding: 60px 0 50px 0;
    @media ($smartphone) {
      padding: 35px 0 15px 0;
    }
    .label {
      color: #5F5F5F;
      font-size: 24px;
      line-height: 28px;
      margin-right: 15px;
      @media ($smartphone) {
        margin-right: 0;
        padding-bottom: 15px;
        display: block;
      }
    }
    .circle {
      &.facebook,
      &.instagram {
        margin: 0 10px;
      }
    }
    .first {
      @media ($smartphone) {
        width: 100%;
        text-align: center;
      }
    }
  }
  .recipe-tip {
    .headline {
      color: #5F5F5F;
      font-size: 24px;
      line-height: 30px;
      text-align: center;
      padding: 35px 0 30px 0;
      @media ($smartphone) {
        font-size: 16px;
        line-height: 20px;
        padding: 20px 0 25px 0;
      }
      span {
        &.blue {
          color: $blue;

          @at-root {
            .site-jarlsberg & {
              color: $jarlsberg-blue;
            }
          }
        }
      }
    }
  }
}

.site-tineasia {
  h2 {
    font-size: 44px;
    line-height: 50px;
    padding-bottom: 0;
    font-weight: bolder;

    @media ($smartphone) {
      font-size: 24px;
      line-height: 30px;
    }
  }
  h3 {
    font-size: 30px;
    line-height: 34px;
    padding-bottom: 0;
    font-weight: bolder;

    @media ($smartphone) {
      font-size: 20px;
      line-height: 24px;
    }
  }
  h4 {
    font-size: 22px;
    line-height: 24px;
    padding-bottom: 0;
    font-weight: bolder;

    @media ($smartphone) {
      font-size: 16px;
      line-height: 18px;
    }
  }
  h5 {
    font-size: 16px;
    line-height: 18px;
    padding-bottom: 0;
    font-weight: bolder;

    @media ($smartphone) {
      font-size: 14px;
      line-height: 16px;
    }
  }

  h6 {
    font-size: 12px;
    line-height: 14px;
    padding-bottom: 0;
    font-weight: bolder;

    @media ($smartphone) {
      font-size: 10px;
      line-height: 12px;
    }
  }
}

@mixin modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  opacity: 0;
  visibility: hidden;
  transition: opacity .25s ease;
  a {
    color: $black;
    font-size: 20px;
    line-height: 20px;
  }
  &.region-selector,
  &.video-player {
    z-index: 1000;
  }
  &.region-selector {
    background-position: center;
    .icon-region-selector {
      margin: 2rem auto;
      text-align: center;
      width: 100%;
      font-size: 50px;
      padding: 0.3em;

      &.icon-logo-jarlsberg {
        svg {
          margin: 0 auto;
        }
        height: auto;
      }
    }
    a {
      font-weight: 500;
      font-size: 24px;
    }
    .headline {
      text-align: center;
      font-size: 30px;
      @media ($desktop) {
        margin-top: 3rem;
      }
    }

    .content {
      background-color: rgba(256, 256, 256, .7);
      position: relative;
      min-width: 50%;
      min-height: 50%;
      padding: 1rem 2rem 2rem;
    }
  }
  a:not(.icon) {
    &:hover,
    &.active {
      text-decoration: underline;
      color: $jarlsberg-blue;
    }
  }
  .headline {
    color: $black;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 20px;
  }
  .nest {
    @media ($smartphone) {
      width: 100%;
    }
  }
  ul {
    text-align: center;
    li {
      padding: 10px 0;

      @media ($smartphone) {
        padding: 3px 0;
      }
    }
  }
  .icon {
    &.icon-cross {
      position: absolute;
      top: 55px;
      right: 55px;
      z-index: 5;
      @media ($smartphone) {
        font-size: 26px;
        line-height: 26px;
        top: 32px;
        right: 28px;
      }
    }
  }
  &.show {
    opacity: 1;
    visibility: visible;
    overflow: scroll;
    background: #fff;

    .icon-cross {
      color: #000;
    }
  }
}
.modal {
  @include modal();
}

$spacing__menu-bar--desktop: 117px;
$spacing__menu-bar--mobile: 90px;

.site-tineasia {
	font-family: $tineasia-font;
	padding-top: $spacing__menu-bar--desktop;

	@media ($smartphone) {
		padding-top: $spacing__menu-bar--mobile;
	}
	.modal.region-selector .icon-region-selector {
		background-color: $tineasia-blue;
	}

	.nest.instaflex {
		padding: 19px;
	}

	.removeButton {
		display: none;
	}

	.btn.blue.filled {
		color: white;
	}

	.nest {
		padding: 0px 10px;
	}

	.mod-articleview {
		background-color: #fff;

		&.blue {
			background-color: #002B45;
				.header {
					color: #fff;
				}
				.textfield {
					color: #fff;
				}
				.readmorebutton {
					color: #fff;
				}
				.article-container .article-column .subject {
					background-color: $white;
					color: #002B45;
				}
		}

		&.bright {
			background-color: #fff;
			.header {
				color: #4A4A4A;
			}
			.textfield {
				color: #4A4A4A;
			}
			.readmorebutton {
				color: #4A4A4A;
			}
		}

		&.dark {
			background-color: #262626;
			.header {
				color: #fff;
			}
			.textfield {
				color: #fff;
			}
			.readmorebutton {
				color: #fff;
			}
			.article-container .article-column .subject {
				background-color: none;
			}
		}
	}

	.article-container {
	display: flex;
	flex-wrap: wrap;
	font-size: 24px;
	font-weight: 400;
	transform: translateZ(0);
	margin-bottom: 20px;

		@media ($smartphone) {
			display: block;
		}
		.article-column{
			flex-basis: 30%;
			border-radius: 5px;
			margin: 6px;
			padding: 5px;
			.subject {
				background-color: #002B45;
				color: #fff;
				font-size: 24px;
				font-weight: 400;
				padding: 0px 10px 40px 10px;
				border-radius: 5px;
				margin-bottom: 15px;
				margin-top: 15px;
				max-height: 60px;
			}
			.article-link {
				margin: auto;
			}
		}
		.text {
			font-size: 24px;
			color: #415242;
			min-height: 195px;
			line-height: 30px;
			.textfield {
				padding: 5px;
			}
		}
		.readmorebutton {
			color: #4A4A4A;


			@media ($tablet) {

			}

			@media ($desktop) {

			}
		}
	}

	.header {
		font-size: 2.6rem;
		line-height: 1.125;
		padding: 1.25rem 0 1.25rem 0.625rem;
	}

	@media (max-width: 768px) {
		div.header {
		font-size: 2rem;
		padding: 1rem 0 1rem 0.5rem;
		}
	}

	.mod-thumbnailslider .slider .slider-viewport .stripe .slide .text {
		color: white;
	}
	.mod-topimage .text-content {
			padding: 0 1em 1em 1em;
	    background-color: rgba(0, 43, 69, 0.7);
	}

	.mod-topimage:not(.parent-article) {
		background-repeat: no-repeat;
		height: 540px;
		background-size: cover !important;

		@media ($smartphone) {
			height: 310px !important;
			background-position: top;
		}

        .nest{
            display: flex;
            justify-content: center;

			.text-center {
				align-self: center;
				position: relative;
				top: 4.1em;
				width: auto;

				h1 {
					margin-top: 0;
					margin-bottom: 0;
    			padding-bottom: 0.8rem;
    			padding-top: 0.8rem;
    			background-color: rgba(0, 43, 69, 0.7);
					@media ($smartphone) {
						margin-bottom: 0;
					}
				}
			}
      }
    }

	h1, h2, h3, h4, h5, h6 {
		font-family: $tineasia-font;
		font-weight: 400;
	}
	.bot .fullwidth>.crow.crow>* {
		color: white;
	}
	.bot {
		background-color: $tineasia-blue;
		display: block;
		padding: 2em 0;
		border-top: 0.75rem solid rgba(0,0,0,0.15);

		@media ($smartphone) {
			padding-bottoM: 0;

			> .crow {
				@include uncrow();
			}
		}

		a {
			color: white;
		}

		&--illustration {
			width: 100%;

			.image {
				height: 16em;
				background-color: $tineasia-blue;
				background-image: url('../images/tine-asia-footer.png');
				background-size: contain;
				background-repeat: repeat-x;
				background-position: bottom;

				@media ($tablet) { height: 14em; }
				@media ($smartphone) { height: 8em; }
			}

			.filler {
				background: #fcfcfc; // bg color on mountains
				height: 5em;

				@media ($smartphone) {
					height: 2em;
				}
			}
		}

		.fullwidth {
			padding-bottom: 0;
		}
	}

	.top .navigation a{
		font-family: $tineasia-font;
		font-size: $tineasia-font-size;
		font-weight: 500;
	}
	.top .fullwidth {
		background-color: $tineasia-blue;
		font-family: $tineasia-font;
		padding: 25px 0 20px 0;

		@media ($smartphone) {
			padding: 10px 0;
		  }
	}
	.top .sticky {
		padding: 15px 0 10px 0;
	}
	.top .fullwidth.sticky {
		display: block;
		background-color: none;
		@media ($smartphone) {
			padding: 10px 0;
		  }
	}
	.top .sticky .navigation .go-home span {
		line-height: 55px;
	}
	.top .sticky .navigation .lang-selector {
		top: 23px;
	}
	.top .navigation .lang-selector {
		top: 22px;
	}
}

body {
	font-size: $font-size;
	line-height: $line-height;
	font-family: $font-family;
	color: $text-color;
	background-color: $bg-color;
	overflow: hidden;
	font-weight: 300;
}

* {
	box-sizing: border-box;
}

sup {
	line-height: 0;
	font-size: 80%;
}

.page-index {
	.mod-card {
		margin: 20px 0;
	}
}

.show-smartphone {
	display: none;
	@media ($smartphone) {
		display: inline-block;
	}
}
.hide-smartphone {
	display: inline-block;
	@media ($smartphone) {
		display: none;
	}
}


[v-cloak] { opacity: 0; }

#wrapper {
	transition: opacity .6s ease;
}

h1, h2, h3, h4, h5, h6 {
	font-family: 'DIN Next', Arial;
	font-weight: 400;
}
.site-brunost {
	h1, h2, h3, h4, h5, h6 {
		font-family: 'Sanchez', Arial;
	}
}

.site-jarlsberg {
	font-family: $jarlsberg-text-font;

  body {
    font-size: 18px;
  }

  .headline {
    font-family: $jarlsberg-headline-font;
  }

	// Hack to avoid to much thymeleaf
	.pinterest {
		display: none;
	}

	h1, h2, h3, h4, h5, h6 {
		font-family: $jarlsberg-header-font;
	}

	p {
		font-family: $jarlsberg-text-font;
	}
}

h1  {
	font-size: 72px;
}

h2  {
	font-size: 48px;
}

h3  {
	font-size: 36px;
	@media ($smartphone) {
		font-size: 24px;
	}
}

figure  {
	margin: 0;
}

p {
	font-family: 'Open Sans', Arial;
}

a {
	text-decoration: none;
	color: $link-color;

  @at-root {
    .site-jarlsberg & {
      color: $jarlsberg-blue;
    }
  }
	&:hover {
		color: $link-hover-color;

    @at-root {
      .site-jarlsberg & {
        color: darken($jarlsberg-blue, 10);
      }
    }
	}
}

@mixin inline {
	display: inline-block;
	*display: inline; // IE7 inline-block hack
	*zoom: 1;
}

ul {
	&.inline {
		list-style: none;
		padding: 0;
		margin: 0;
		li {
			@include inline();
		}
	}
	&.unstyled {
		list-style: none;
		padding: 0;
		margin: 0;
		li {
			display: block;
		}
	}
}

@mixin clearfix() {
	&:before,
	&:after {
		content: " ";
		display: table;
	}
	&:after {
		clear: both;
	}
}

.clearfix {
	@include clearfix();
}

.spacer {
	height: 40px;
	&.half {
		height: 20px;
	}
	&.quarter {
		height: 10px;
	}
	&.fourth {
		height: 5px;
	}
}

.fullwidth {
	width: 100%;
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

.text-left {
	text-align: left;
}

.relative {
	position: relative;
}

.splash,
.absolute {
	position: absolute;
}

img {
	width: 100%;
	max-width: 100%;
}

.image {
	display: block;
	line-height: 0;
}

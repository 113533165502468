.back-to-top {
	position: fixed;
	right: 2rem;
	bottom: 2rem;
	border-radius: 50%;
	padding: 0.6rem 1rem;
	background: #002B45;
	font-size: 2rem;
	border: 0;
	cursor: pointer;
	opacity: 0;
	visibility: hidden;
	transition: 250ms ease-in-out;
	box-shadow: 0 1px 8px rgba(0,0,0,0.25);

	&:hover {
		background: #002B45;
	}

	#back-to-top-icon {
		width: 3rem;
		height: 3rem;
		color: #fff;
		margin-top: 0.5rem;
	}
}

.fade-in-out {
	opacity: 1;
	visibility: visible;
}

.mod-product {
  background-color: $white;
  color: #5F5F5F;
  padding-top: 30px;
  padding-bottom: 60px;
  @media ($smartphone) {
    padding-left: 10px;
    padding-right: 10px;
  }
  .left-side {
    padding-right: 130px;
    @media ($smartphone) {
      padding-right: 0;
    }
  }
  &.hidden {
    display: none;
  }
  @media ($smartphone) {
    .crow {
      @include uncrow();
    }
  }
  hr {
    &.full {
      margin-bottom: 30px;
    }
  }
  h1, h2, h3, h4, h5, h6 {
    color: #4A4A4A;
    text-transform: uppercase;
    margin: 0;
  }
  h3 {
    font-size: 18px;
    line-height: 24px;
    padding-bottom: 15px;
    @media ($smartphone) {
      padding-bottom: 15px;
    }
  }
  h4 {
    font-size: 18px;
    line-height: 24px;
    padding-bottom: 15px;
  }
  p {
    margin: 0 0 30px 0;

    &:last-child {
      margin: 0;
    }
  }
  ul {
    margin-bottom: 30px;
  }
  li {
    padding: 5px 0;
    text-align: right;
    @include clearfix();
  }
  .left-side {
    font-size: 18px;
    line-height: 24px;
    @media ($smartphone) {
      font-size: 16px;
      line-height: 22px; 
    }
  }
  .right-side {
    font-size: 16px;
    line-height: 22px;
    .go-left {
      float: left;
    }
  }
}
.mod-categorystripe {
  .recipe-search-box {
    margin: 3rem 1rem 0 1rem;
    padding: 2.5rem 1.5rem;
    height: 3rem;
    width: calc(100% - 2rem);
    max-width: 500px;
    font-size: 1.7rem;
    border-radius: 0.5rem;
    border: 1px solid #c8c8c8;
    text-indent: 0.5rem;
  }

  background-color: #F6F6F6;
  padding: 10px 0;
  text-align: center;
  @media ($smartphone) {
    padding: 5px;
  }
  .padding {
    padding: 10px;
    @media ($smartphone) {
      padding: 5px;
    }
  }
  .nest {
    @media ($smartphone) {
      margin-right: -20px;
      overflow-y: scroll;
    }
    ul {
      @media ($smartphone) {
        text-align: left;
        width: 100%;
        padding-right: 5px;
      }
    }
    .ws-2 {
      @media ($smartphone) {
        width: 50%;
      }
    }
  }
  .line {
    position: absolute;
    left: 0;
    top: 100%;
    margin-top: 10px;
    background-color: $blue;
    width: 100%;
    height: 5px;
    border-radius: 2px;
    opacity: 0;
    visibility: hidden;
    transition: opacity .25s ease;
  }
  .category-list {
    width: 100%;
    @media ($smartphone) {
      width: 100% !important;
      padding-right: 0 !important;
    }
  }
  .category-card {
    display: block;
    background-size: cover;
    background-position: center center;
    height: 80px;
    width: 100%;
    border-radius: 5px;
    transition: opacity .25s ease;
    margin: 10px;
    position: relative;
    @media ($smartphone) {
      width: auto;
      margin: 0;
      height: 50px;
    }
    &.active {
      .line {
        opacity: 1;
        visibility: visible;
      }
    }

    .tone {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0,0,0,.15);
      border-radius: 5px;
    }

    span {
      font-size: 16px;
      line-height: 18px;
      color: $white;
      font-weight: 400;
      text-transform: uppercase;
      text-align: center;
      position: relative;
    }

    &:hover {
      opacity: .8;
    }
  }
}

.site-jarlsberg {
  .mod-categorystripe {
    .line {
      background-color: $jarlsberg-blue;
    }
  }
}

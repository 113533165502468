.top {
  .fullwidth {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    padding: 35px 0 30px 0;
    z-index: 999;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#88bfe8+0,70b0e0+100;Blue+3D+%2317 */
    background: transparent; /* Old browsers */
    background: -moz-linear-gradient(top, rgba(0,0,0,.4) 0%, transparent 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0,0,0,.4) 0%,transparent 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0,0,0,.4) 0%,transparent 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='rgba(0,0,0,.4)', endColorstr='transparent',GradientType=0 ); /* IE6-9 */

    transition: all .25s ease;
    @media ($smartphone) {
      padding: 25px 0;
      position: fixed;
    }
  }
  .nest {
    padding: 0;
    @media ($tablet) {
      padding: 0 20px;
      width: 100%;
    }
  }
  .navigation {
    position: relative;
    z-index: 50;
    li {
      //padding: 0 30px;
      text-align: center;
      &.w-20 {
        @media ($tablet) {
          width: 17%;
        }
      }
    }
    a {
      color: $white;
      border-bottom: 2px solid transparent;
      font-size: 14px;
      line-height: 14px;
      text-transform: uppercase;
      padding-bottom: 5px;
      font-weight: 400;
      display: inline-block;
      font-family: 'DIN Next', Arial;
      &.active,
      &:hover:not(.logo):not(.lang-selector):not(.home) {
        border-color: $white;
      }
    }
    .lang-selector {
      position: absolute;
      top: 10px;
      right: -80px;
      @media ($tablet) {
        right: 0;
      }
    }
    .go-home {
      position: absolute;
      top: 6px;
      left: -60px;
      @media ($tablet) {
        left: 0;
      }
    }

    .home,
    .go-home {
      display: none;
    }
    .icon {
      &.icon-cross {
        display: none;
      }
    }
  }

  .smartphone-navigation {
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    display: none;
    .icon {
      &.icon-burger {
        position: absolute;
        top: 32px;
        right: 25px;
      }
			&.fas.fa-bars {
				position: absolute;
				top: 32px;
				right: 25px;
			}
    }
  }
  .fullwidth {
    &:not(.sticky) {
      .icon-logo-brunost {
        .path19,
        .path20,
        .path21,
        .path22,
        .path23,
        .path24,
        .path25,
        .path26,
        .path27,
        .path28,
        .path29,
        .path30,
        .path31,
        .path32,
        .path33,
        .path34,
        .path35 {
          &:before {
            color: #fff;
          }
        }
      }
    }
  }
  .sticky {
    padding: 15px 0 10px 0;
    position: fixed;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
    @media ($smartphone) {
      padding: 0 0;
    }
    .navigation {
      a {
        color: #ffffff;
        @media ($smartphone) {
          color: $white;
        }
      }
      .lang-selector {
        top: 10px;
      }
      .go-home {
        display: block;
        span {
          font-size: 22px;
          line-height: 22px;
        }
        @media ($smartphone) {
          display: none;
        }
      }
      .icon {
        &.icon-logo-snofrisk,
        &.icon-logo-brunost {
          color: #004B86;
          font-size: 30px;
          line-height: 30px;
          transition: font-size .25s ease;
        }
        &.icon-logo-brunost {
          font-size: 46px;
          line-height: 46px;
        }
        &.icon-small-arrow-down {
          color: #4A4A4A;
        }
		    &.icon-logo-jarlsberg svg {
		      margin-top: -8px;
          height: 90px;
        }
      }
    }
    .smartphone-navigation {
      padding: 5px 0;
      transition: padding .25s ease;
      .icon {
        &.icon-logo-snofrisk,
        &.icon-logo-brunost {
          color: #004B86;
          font-size: 30px;
          line-height: 30px;
          transition: font-size .25s ease;
        }
        &.icon-burger {
          color: #424242;
          font-size: 24px;
          line-height: 24px;
          top: 10px;
          right: 10px;
          transition: top .25s ease,
                      right .25s ease;
        }
				&.fas.fa-bars {
					color: #424242;
					font-size: 24px;
					line-height: 24px;
					top: 10px;
					right: 10px;
					transition: top .25s ease,
					right .25s ease;
				}
      }
    }
  }

  @media ($smartphone) {
    .smartphone-navigation {
      display: block;
    }
    .navigation {
      @include modal();
      @include crow();
      margin: 0;
      opacity: 0;
      visibility: hidden;
      a {
        padding: 0;
        border: 0;
      }
      .inline {
        list-style: none;
        padding: 0;
        margin: 0;
        @include uncrow();
        li {
          display: block;
          text-align: center;
          padding: 10px 0;
        }
      }
      .logo {
        display: none !important;
      }
      .lang-selector {
        position: static;
      }
      .home {
        @include inline();
      }
      .icon {
        &.icon-cross {
          @include inline();
        }
      }
    }
  }
}
.site-brunost {
  .top {
    .navigation {
      .lang-selector {
        top: 17px;
      }
      .go-home {
        top: 14px;
      }
    }
    .sticky {
      .navigation {
        .lang-selector {
          top: 13px;
        }
      }
    }
  }
}

.site-jarlsberg {
  .top .sticky .smartphone-navigation .icon.icon-burger {
    top: 20px;
  }

  .top {
		.navigation {
      .lang-selector {
        top: 15px;
      }
      .go-home {
        top: 18px;
      }

			a {
				font-family: $jarlsberg-header-font;


				&.active:not(.logo):not(.lang-selector):not(.home) {
					margin-top: -25px;
					padding-top: 25px;
					background: transparent url("../images/Jarlsberg_graphic_swirl.png") no-repeat top center;
					background-size: 40px;
					border-color: $jarlsberg-yellow;

					@media ($smartphone) {
						margin-top: 0;
						padding-top: 0;
						background: none;
					}
				}
			}
		}
	}
}

.site-tineasia {
  .top {

    .navigation {
      .go-home {
        display: inline;
        position: absolute;
        top: 25px;
        margin-left: 24px;
        font-size: 22px;
      }
    }
  }
  .fullwidth.sticky {
    .navigation {
      .go-home {
        top: 9px;
        font-size: 22px;
      }
    }
    .smartphone-navigation .icon.icon-burger {
      top: 30px;
    }

		.smartphone-navigation .icon.icon.fas.fa-bars {
			top: 30px;
		}

    .sticky {
      .smartphone-navigation {
        padding: 0;

        .icon.icon-burger {
          color: #fff;
          top: 30px;
          right: 25px;
          font-size: 30px;
          line-height: 30px;
        }
				.icon.fas.fa-bars {
					color: #fff;
					top: 30px;
					right: 25px;
					font-size: 30px;
					line-height: 30px;
				}
      }
    }

    .navigation .icon.icon-small-arrow-down {
      color: #fff;
    }
  }
}

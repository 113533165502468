/* This stylesheet generated by Transfonter (http://transfonter.org) on October 21, 2016 1:37 PM */

/* Fonts.com validation for DIN Next font */
@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=51a7632b-ad03-4c46-ab6f-6272f7c5a2a4");
/* Fonts.com validation for Sanchez font */
@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=168e8977-91f7-4f6b-a5d0-91bf0594fdd3");

@font-face {// Sanchez semi bold
  font-family:"Sanchez";
  src: url("../fonts/Sanchez/956f8aca-f2b6-4a1a-a9c6-5a0851f705f0.eot?#iefix");
  src: url("../fonts/Sanchez/956f8aca-f2b6-4a1a-a9c6-5a0851f705f0.eot?#iefix") format("eot"),
       url("../fonts/Sanchez/db279e45-f853-4df4-b340-d38655159152.woff2") format("woff2"),
       url("../fonts/Sanchez/63787aea-00f5-4be1-8161-27f792e49d96.woff") format("woff"),
       url("../fonts/Sanchez/2e75e290-0f2b-4c03-81f7-2586fa49a381.ttf") format("truetype"),
       url("../fonts/Sanchez/84235bea-2ac2-4050-b8c0-3c533e05e7fd.svg#84235bea-2ac2-4050-b8c0-3c533e05e7fd") format("svg");
  font-weight: 600;
}

@font-face{//Sanchez regular
  font-family:"Sanchez";
  src:url("../fonts/Sanchez/73bb99fc-9616-421d-ac7d-45e291fc79b2.eot?#iefix");
  src:url("../fonts/Sanchez/73bb99fc-9616-421d-ac7d-45e291fc79b2.eot?#iefix") format("eot"),
      url("../fonts/Sanchez/bf0ad6dd-6d46-4de8-a364-087a150ecb1a.woff2") format("woff2"),
      url("../fonts/Sanchez/3866e175-a3b9-4e65-8f40-4a717ff749e2.woff") format("woff"),
      url("../fonts/Sanchez/5686fe9c-f4fb-4255-b726-bbe055aa1285.ttf") format("truetype"),
      url("../fonts/Sanchez/c597ea5c-ce7e-4db2-917c-27efb9a8a082.svg#c597ea5c-ce7e-4db2-917c-27efb9a8a082") format("svg");
  font-weight: 400;
}

@font-face{// W01 Light
  font-family:"DIN Next";
  src: url("../fonts/DIN-Next/3e0b2cd7-9657-438b-b4af-e04122e8f1f7.eot?#iefix");
  src: url("../fonts/DIN-Next/3e0b2cd7-9657-438b-b4af-e04122e8f1f7.eot?#iefix") format("eot"),
       url("../fonts/DIN-Next/de1216be-00c2-43c0-9c45-3e7f925eb519.woff2") format("woff2"),
       url("../fonts/DIN-Next/bc176270-17fa-4c78-a343-9fe52824e501.woff") format("woff"),
       url("../fonts/DIN-Next/3516f91d-ac48-42cd-acfe-1be691152cc4.ttf") format("truetype"),
       url("../fonts/DIN-Next/d1b1e866-a411-42ba-8f75-72bf28e23694.svg#d1b1e866-a411-42ba-8f75-72bf28e23694") format("svg");
  font-weight: 300;
  font-style: normal;
}
@font-face{// W01 Regular
  font-family:"DIN Next";
  src: url("../fonts/DIN-Next/da088ecc-a53c-4831-a95b-6fd81b5b338a.eot?#iefix");
  src: url("../fonts/DIN-Next/da088ecc-a53c-4831-a95b-6fd81b5b338a.eot?#iefix") format("eot"),
       url("../fonts/DIN-Next/44e7b0fa-6c8d-43c2-b19e-f1e3ce9ea57c.woff2") format("woff2"),
       url("../fonts/DIN-Next/c5a7f89e-15b6-49a9-8259-5ea665e72191.woff") format("woff"),
       url("../fonts/DIN-Next/868c94b5-ca49-46b7-b62b-297f8d861125.ttf") format("truetype"),
       url("../fonts/DIN-Next/e3145f65-29b5-4f7f-8c6e-2dc135021b6a.svg#e3145f65-29b5-4f7f-8c6e-2dc135021b6a") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face{// W01 Italic
  font-family:"DIN Next";
  src: url("../fonts/DIN-Next/4393bcd9-65c9-4c71-b688-9a0c17e40ebf.eot?#iefix");
  src: url("../fonts/DIN-Next/4393bcd9-65c9-4c71-b688-9a0c17e40ebf.eot?#iefix") format("eot"),
       url("../fonts/DIN-Next/f37f8098-b16e-405f-ab24-bd595a5d5762.woff2") format("woff2"),
       url("../fonts/DIN-Next/c5635d60-91fa-4e4b-8a51-41c97fc744c3.woff") format("woff"),
       url("../fonts/DIN-Next/de34232f-b870-49c0-a6c0-24c25db73bc2.ttf") format("truetype"),
       url("../fonts/DIN-Next/35916fb2-3ed3-4d39-bec8-74155a53b1f0.svg#35916fb2-3ed3-4d39-bec8-74155a53b1f0") format("svg");
  font-weight: normal;
  font-style: italic;
}
@font-face{// W01 Medium
  font-family:"DIN Next";
  src: url("../fonts/DIN-Next/324dee78-6585-4c13-8ca3-5817cc600d84.eot?#iefix");
  src: url("../fonts/DIN-Next/324dee78-6585-4c13-8ca3-5817cc600d84.eot?#iefix") format("eot"),
       url("../fonts/DIN-Next/be607c9f-3c81-41b4-b7ad-17af005c80bb.woff2") format("woff2"),
       url("../fonts/DIN-Next/4bf0ead4-e61b-4992-832b-6ff05828d99f.woff") format("woff"),
       url("../fonts/DIN-Next/73713a0b-8f6a-4c4e-b8a8-7cf238e632f6.ttf") format("truetype"),
       url("../fonts/DIN-Next/b90b6bdf-15e2-4544-9fe0-92c79fff0ecd.svg#b90b6bdf-15e2-4544-9fe0-92c79fff0ecd") format("svg");
  font-weight: 500;
  font-style: normal;
}
@font-face{// W01 Bold
  font-family:"DIN Next";
  src: url("../fonts/DIN-Next/b32ab78b-6c51-4bd3-bc37-4edcec0331f8.eot?#iefix");
  src: url("../fonts/DIN-Next/b32ab78b-6c51-4bd3-bc37-4edcec0331f8.eot?#iefix") format("eot"),
       url("../fonts/DIN-Next/121784c1-863c-4e49-b682-625ea7de55b0.woff2") format("woff2"),
       url("../fonts/DIN-Next/9949035f-6226-4080-a14a-bf7d94ffb1e1.woff") format("woff"),
       url("../fonts/DIN-Next/6241bd09-15c2-485e-9796-9db8d0aa6974.ttf") format("truetype"),
       url("../fonts/DIN-Next/af94da3e-4232-416a-b6bc-f39f85dd5db6.svg#af94da3e-4232-416a-b6bc-f39f85dd5db6") format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/Open-Sans/OpenSans-Italic.eot');
  src: url('../fonts/Open-Sans/OpenSans-Italic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Open-Sans/OpenSans-Italic.woff2') format('woff2'),
    url('../fonts/Open-Sans/OpenSans-Italic.woff') format('woff'),
    url('../fonts/Open-Sans/OpenSans-Italic.ttf') format('truetype'),
    url('../fonts/Open-Sans/OpenSans-Italic.svg#OpenSans-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/Open-Sans/OpenSans-SemiboldItalic.eot');
  src: url('../fonts/Open-Sans/OpenSans-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Open-Sans/OpenSans-SemiboldItalic.woff2') format('woff2'),
    url('../fonts/Open-Sans/OpenSans-SemiboldItalic.woff') format('woff'),
    url('../fonts/Open-Sans/OpenSans-SemiboldItalic.ttf') format('truetype'),
    url('../fonts/Open-Sans/OpenSans-SemiboldItalic.svg#OpenSans-SemiboldItalic') format('svg');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/Open-Sans/OpenSans-Semibold.eot');
  src: url('../fonts/Open-Sans/OpenSans-Semibold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Open-Sans/OpenSans-Semibold.woff2') format('woff2'),
    url('../fonts/Open-Sans/OpenSans-Semibold.woff') format('woff'),
    url('../fonts/Open-Sans/OpenSans-Semibold.ttf') format('truetype'),
    url('../fonts/Open-Sans/OpenSans-Semibold.svg#OpenSans-Semibold') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/Open-Sans/OpenSans-Light.eot');
  src: url('../fonts/Open-Sans/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Open-Sans/OpenSans-Light.woff2') format('woff2'),
    url('../fonts/Open-Sans/OpenSans-Light.woff') format('woff'),
    url('../fonts/Open-Sans/OpenSans-Light.ttf') format('truetype'),
    url('../fonts/Open-Sans/OpenSans-Light.svg#OpenSans-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/Open-Sans/OpenSans-BoldItalic.eot');
  src: url('../fonts/Open-Sans/OpenSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Open-Sans/OpenSans-BoldItalic.woff2') format('woff2'),
    url('../fonts/Open-Sans/OpenSans-BoldItalic.woff') format('woff'),
    url('../fonts/Open-Sans/OpenSans-BoldItalic.ttf') format('truetype'),
    url('../fonts/Open-Sans/OpenSans-BoldItalic.svg#OpenSans-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/Open-Sans/OpenSans-Extrabold.eot');
  src: url('../fonts/Open-Sans/OpenSans-Extrabold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Open-Sans/OpenSans-Extrabold.woff2') format('woff2'),
    url('../fonts/Open-Sans/OpenSans-Extrabold.woff') format('woff'),
    url('../fonts/Open-Sans/OpenSans-Extrabold.ttf') format('truetype'),
    url('../fonts/Open-Sans/OpenSans-Extrabold.svg#OpenSans-Extrabold') format('svg');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/Open-Sans/OpenSans-ExtraboldItalic.eot');
  src: url('../fonts/Open-Sans/OpenSans-ExtraboldItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Open-Sans/OpenSans-ExtraboldItalic.woff2') format('woff2'),
    url('../fonts/Open-Sans/OpenSans-ExtraboldItalic.woff') format('woff'),
    url('../fonts/Open-Sans/OpenSans-ExtraboldItalic.ttf') format('truetype'),
    url('../fonts/Open-Sans/OpenSans-ExtraboldItalic.svg#OpenSans-ExtraboldItalic') format('svg');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/Open-Sans/OpenSans.eot');
  src: url('../fonts/Open-Sans/OpenSans.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Open-Sans/OpenSans.woff2') format('woff2'),
    url('../fonts/Open-Sans/OpenSans.woff') format('woff'),
    url('../fonts/Open-Sans/OpenSans.ttf') format('truetype'),
    url('../fonts/Open-Sans/OpenSans.svg#OpenSans') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/Open-Sans/OpenSansLight-Italic.eot');
  src: url('../fonts/Open-Sans/OpenSansLight-Italic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Open-Sans/OpenSansLight-Italic.woff2') format('woff2'),
    url('../fonts/Open-Sans/OpenSansLight-Italic.woff') format('woff'),
    url('../fonts/Open-Sans/OpenSansLight-Italic.ttf') format('truetype'),
    url('../fonts/Open-Sans/OpenSansLight-Italic.svg#OpenSansLight-Italic') format('svg');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/Open-Sans/OpenSans-Bold.eot');
  src: url('../fonts/Open-Sans/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Open-Sans/OpenSans-Bold.woff2') format('woff2'),
    url('../fonts/Open-Sans/OpenSans-Bold.woff') format('woff'),
    url('../fonts/Open-Sans/OpenSans-Bold.ttf') format('truetype'),
    url('../fonts/Open-Sans/OpenSans-Bold.svg#OpenSans-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/Open-Sans/OpenSans-Italic.eot');
  src: url('../fonts/Open-Sans/OpenSans-Italic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Open-Sans/OpenSans-Italic.woff2') format('woff2'),
    url('../fonts/Open-Sans/OpenSans-Italic.woff') format('woff'),
    url('../fonts/Open-Sans/OpenSans-Italic.ttf') format('truetype'),
    url('../fonts/Open-Sans/OpenSans-Italic.svg#OpenSans-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'eideticmodern_regular';
  src: url('../fonts/EideticModern/eideticmodernregular-webfont.woff') format('woff');
  font-weight: normal;
}

@font-face {
  font-family: 'gibson-regular';
  src: url('../fonts/Gibson/gibson-regular-webfont.woff') format('woff');
}

@font-face {
  font-family: 'gibson-semibold';
  src: url('../fonts/Gibson/gibson-semibold-webfont.woff') format('woff');
}
@font-face {
  font-family: 'Telefon';
  src: url('../fonts/Telefon//TelefonWeb-Light.eot');
  src: 
    url('../fonts/Telefon/TelefonWeb-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Telefon/TelefonWeb-Light.woff2') format('woff2'),
    url('../fonts/Telefon/TelefonWeb-Light.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Telefon';
  src: url('../fonts/Telefon/TelefonWeb-LightItalic.eot');
  src: 
    url('../fonts/Telefon/TelefonWeb-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Telefon/TelefonWeb-LightItalic.woff2') format('woff2'),
    url('../fonts/Telefon/TelefonWeb-LightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Telefon';
  src: url('../fonts/Telefon/TelefonWeb-Blond.eot');
  src: 
    url('../fonts/Telefon/TelefonWeb-Blond.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Telefon/TelefonWeb-Blond.woff2') format('woff2'),
    url('../fonts/Telefon/TelefonWeb-Blond.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Telefon';
  src: url('../fonts/Telefon/TelefonWeb-BlondItalic.eot');
  src: 
    url('../fonts/Telefon/TelefonWeb-BlondItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Telefon/TelefonWeb-BlondItalic.woff2') format('woff2'),
    url('../fonts/Telefon/TelefonWeb-BlondItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Telefon';
  src: url('../fonts/Telefon/TelefonWeb-Normal.eot');
  src: 
    url('../fonts/Telefon/TelefonWeb-Normal.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Telefon/TelefonWeb-Normal.woff2') format('woff2'),
    url('../fonts/Telefon/TelefonWeb-Normal.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Telefon';
  src: url('../fonts/Telefon/TelefonWeb-NormalItalic.eot');
  src: 
    url('../fonts/Telefon/TelefonWeb-NormalItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Telefon/TelefonWeb-NormalItalic.woff2') format('woff2'),
    url('../fonts/Telefon/TelefonWeb-NormalItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Telefon';
  src: url('../fonts/Telefon/TelefonWeb-Medium.eot');
  src: 
    url('../fonts/Telefon/TelefonWeb-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Telefon/TelefonWeb-Medium.woff2') format('woff2'),
    url('../fonts/Telefon/TelefonWeb-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Telefon';
  src: url('../fonts/Telefon/TelefonWeb-MediumItalic.eot');
  src: 
    url('../fonts/Telefon/TelefonWeb-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Telefon/TelefonWeb-MediumItalic.woff2') format('woff2'),
    url('../fonts/Telefon/TelefonWeb-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Telefon';
  src: url('../fonts/Telefon/TelefonWeb-Bold.eot');
  src: 
    url('../fonts/Telefon/TelefonWeb-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Telefon/TelefonWeb-Bold.woff2') format('woff2'),
    url('../fonts/Telefon/TelefonWeb-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Telefon';
  src: url('../fonts/Telefon/TelefonWeb-BoldItalic.eot');
  src: 
    url('../fonts/Telefon/TelefonWeb-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Telefon/TelefonWeb-BoldItalic.woff2') format('woff2'),
    url('../fonts/Telefon/TelefonWeb-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Telefon';
  src: url('../fonts/Telefon/TelefonWeb-Black.eot');
  src: 
    url('../fonts/Telefon/TelefonWeb-Black.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Telefon/TelefonWeb-Black.woff2') format('woff2'),
    url('../fonts/Telefon/TelefonWeb-Black.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Telefon';
  src: url('../fonts/Telefon/TelefonWeb-BlackItalic.eot');
  src: 
    url('../fonts/Telefon/TelefonWeb-BlackItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Telefon/TelefonWeb-BlackItalic.woff2') format('woff2'),
    url('../fonts/Telefon/TelefonWeb-BlackItalic.woff') format('woff');
  font-weight: 800;
  font-style: italic;
}
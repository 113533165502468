$container-padding: 5rem;
$mobile-width: 400px;
$mobile-height: 350px;

.product-single {
	display: flex;
	flex-wrap: wrap;
	max-width: 850px;
	margin: 0 auto;

	h1 {
		line-height: 100%;
		margin-bottom: 3rem;
		font-size: 4rem;
	}

	.container {
		padding-top: #{$container-padding / 2};
		padding-bottom: #{($container-padding / 2) + 1};

		&--left {
			width: calc(100% / 3); // 33.3%
		}

		&--right {
			width: calc(100% / 1.5); // 66.6%
			padding-left: $container-padding;
		}

		&--image {
			align-self: end;
			text-align: center;
		}

		img {
			width: 100%;
			max-height: 300px;
			object-fit: contain;
		}

		ul li {
			.go-left { float: left; }
			.go-right { text-align: right; }
		}

		@media ($smartphone) {
			width: 100%;
			padding-left: 0;
			text-align: center;
			padding: 3rem;

			img {
				max-width: $mobile-width;
				max-height: $mobile-height;
			}

			ul {
				max-width: $mobile-width;
				margin: 0 auto;
			}
		}
	}
}

$shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;

.list-content {
	margin-bottom: 3rem;

	h1 {
		font-size: 4rem;
		line-height: 100%;
		margin-top: 60px;
		padding-bottom: 0rem;
		margin-bottom: 0rem;
	}

	h2 {
		line-height: 110%;
		color: $tineasia-blue;
	}

	a {
		width: 100%;
		transition: all 150ms ease-in;
		position: relative;
		top: 0;

		&:focus,
		&:hover {
			top: -3px;

			@media ($smartphone) {
				box-shadow: none !important;
			}
		}

		@media ($smartphone) {
			position: unset;
			transition: none;
		}
	}

	.intro-container {
		padding-bottom: 5rem;
		margin-bottom: 5rem;
		border-bottom: 1px solid $gray-light;

		@media ($smartphone) {
			text-align: center;
			border-bottom: 0;
			padding-bottom: 0;
		}
	}

	.items {
		&--with-image {
			position: relative;
			top: -1.5rem;
			width: 100%;

			@media ($smartphone) {
				padding: 0 4rem;
			}

			img {
				height: 250px;
				max-height: 320px;
				object-fit: contain;
				padding: 0 2rem;

				@media ($tablet) {
					height: 230px;
					padding: 0 0.5rem;
				}

				@media ($smartphone) {
					height: auto;
					padding: 0;
				}
			}

			a {
				@extend a; // hover effect gets overwritten without

				display: inline-block;
				padding: 3.5rem 1rem 2rem;
				border-radius: 1rem;

				&:focus,
				&:hover {
					background: #fff;
					box-shadow: $shadow;

					@media ($smartphone) {
						background: none;
					}
				}

				@media ($tablet) {
					padding: 2.5rem 2rem 1rem;
				}
			}

			h2 {
				font-size: 1.75rem;
				font-weight: 600;
				padding-top: 1.5rem;
				position: relative;

				@media ($tablet) {
					padding-top: 0;
				}

				@media ($smartphone) {
					font-size: 2.2rem;
				}
			}

			.ws-4 {
				vertical-align: top;
				padding: 0;

				@media ($smartphone) {
					width: 100%;
					margin-bottom: 4rem;
					border-bottom: 1px solid $gray-light;
					padding-bottom: 4rem;

					&:last-child {
						border-bottom: 0;
						padding-bottom: 0;
						margin-bottom: 0;
					}
				}
			}
		}

		&--no-image {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;

			.item {
				width: 20%;
				padding: 2rem;

				@media ($tablet) {
					width: 25%;
				}

				@media ($smartphone) {
					width: 50%;
					padding: 1.5rem;
				}

				a {
					@extend a;

					display: flex;
					justify-content: center;
					text-align: center;
					background: $tineasia-blue;
					padding: 2px;
					height: 100%;
					min-height: 110px;
					border: 1px solid rgba(0, 0, 0, 0.05);
					border-radius: 0.5rem;

					&:hover,
					&:focus {
						box-shadow: $shadow;
					}

					@media ($smartphone) {
						min-height: 90px;
					}
				}

				h2 {
					font-size: 2rem;
					margin: 0;
					align-self: center;
					padding: 2rem;
					font-weight: 300;
					color: #fff;

					@media ($smartphone) {
						font-size: 2rem;
					}
				}
			}
		}
	}
}

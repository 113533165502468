.video-button {
  height: 40px;
  width: 40px;
  margin: auto;
  z-index: 100;
  background: rgba($bg-color, 0.6);
  border-radius: 100%;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  border: 0;

  svg {
    display: block;
    margin: auto;
    height: 100%;
    width: 50%;

    * {
      color: $text-color;
    }
  }
}

.video-button--play {
  height: 80px;
  width: 80px;
  cursor: pointer;

  &:hover {
    background: $link-color;

    @at-root {
      .site-jarlsberg & {
        background: $jarlsberg-blue;
      }
    }

    svg * {
      color: $bg-color;
    }
  }
}
